#backlink-options-form{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1em;
}
.backlink-options-form-div{
    display: flex;
    align-items: center;
    gap: 1em;
    width: 100%;
}
.backlink-options-form-input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.backlink-options-form-input-container label{
    text-align: left;
    font-size: 0.8rem;
}
.backlink-options-form-input-container > div{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.backlink-options-form-input-container-btns{
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.backlink-options-form-input-container-btns button{
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: grey;
}
.backlink-options-form-input-container-btns button:disabled{
    color: rgb(228, 228, 228);
    pointer-events: none;
}

.backlink-options-form-check-icon{
    color: rgb(181, 180, 180);
}
.backlink-options-form-check-icon-green{
    color: var(--success-clr);
}

.backlink-options-form-btn{
    margin-top: 1em;
    background: var(--secondary-clr);
    border-radius: 5px;
}
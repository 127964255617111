/* Action buttons */
.profile-website-actions{
    position: absolute;
    right: 6em;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.profile-website-actions-btn{
    position: relative;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1;
}
.profile-website-actions-btns{
    position: absolute;
    width: max-content;
    display: flex;
    flex-direction: column;
    background: var(--secondary-clr);
    bottom: -95px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    z-index: 2;
}
.profile-website-actions-btns button{
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--border-clr);
    color: var(--main-clr);
    padding: 0.5em 1em;
    cursor: pointer;
    transition: background 0.3s;
}
.profile-website-actions-btns button:hover{
    background: var(--intense-clr);
}


/* Languages, niches, age & description pop ups */
.profile-languages-wrapper, .profile-website-age-wrapper, .profile-website-niche-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--blur-clr);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
}
.profile-website-languages, .profile-website-age, .profile-website-niche-wrapper > div{
    position: relative;
    width: 80%;
    height: 90vh;
    background: rgb(255, 255, 255);
    box-shadow: var(--material-shadow);
    border: 1px solid var(--border-clr);
    border-radius: var(--radius);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding: 2em;
    overflow: hidden;
}
.profile-website-age > div{
    width: 50%;
}
.profile-website-languages h1, .profile-website-age h1, .profile-website-niche-wrapper > div > h1{
    font-size: 20px;
    font-weight: 500;
    color: var(--font-clr);
    margin: 0;
}
.profile-website-languages p, .profile-website-age p, .profile-website-niche-wrapper > div > p{
    font-size: 15px;
    color: var(--font-light);
    margin: 0;
}
.profile-website-languages-options{
    display: flex;
    flex-flow: row wrap;
    gap: 0.5em;
    overflow: auto;
    padding: 0.5em;
}
.profile-website-languages-options::-webkit-scrollbar {
    width: 1px;
    opacity: 0;
}
.profile-website-languages-single-active, .profile-website-languages-single{
    position: relative;
    display: flex;
    gap: 0.3em;
    padding: 0.5em 1em;
    height: max-content;
    font-weight: 500;
    font-size: 14px;
    border-radius: 25px;
    background: var(--main-light);
}
.profile-website-languages-single-active{
    background: var(--third-clr);
    color: var(--main-clr);
}
.profile-website-languages-single-active button, .profile-website-languages-single button{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--secondary-clr);
    border: none;
    color: var(--main-clr);
}
.profile-website-languages-options > div input{
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.profile-websites-languages-save-btn{
    width: 50%;
    background: var(--secondary-clr);
    color: var(--main-clr);
    border: none;
    border-radius: var(--radius);
    padding: 1em 2em;
    cursor: pointer;
    transition: background 0.3s;
}
.profile-websites-languages-save-btn:hover{
    background: var(--intense-clr);
}
.profile-websites-languages-close-btn{
    position: absolute;
    top: 1em;
    right: 1em;
    width: 40px;
    height: 40px;
    background: var(--main-clr);
    border: 1px solid var(--secondary-clr);
    border-radius: 50%;
    color: var(--secondary-clr);
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s;
}
.profile-websites-languages-close-btn:hover{
    background: var(--secondary-clr);
    color: var(--main-clr);
}


.profile-website-niche-wrapper > div > div{
    width: 80%;
    height: 50%;
    display: flex;
    overflow: hidden;
    margin-top: 2em;
}
.profile-website-niche-wrapper > div > div > div:nth-child(1){
    display: flex;
    flex-flow: row wrap;
    gap: 0.5em;
    overflow: auto;
    padding: 0.5em;
}
.profile-website-niche-wrapper > div > div > div:nth-child(1)::-webkit-scrollbar {
    width: 1px;
    opacity: 0;
}
.profile-website-niche-wrapper > div > div > div:nth-child(2){
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: row wrap;
    gap: 0.5em;
    overflow: auto;
    padding: 0.5em;
}
.profile-website-niche-wrapper > div > div > div:nth-child(2)::-webkit-scrollbar {
    width: 1px;
    opacity: 0;
}
.profile-website-niche, .profile-website-niche-active{
    position: relative;
    height: max-content;
    border: 1px solid var(--border-clr);
    background: var(--main-light);
    color: var(--font-clr);
    border-radius: 25px;
    padding: 0.5em 1em;
    cursor: pointer;
}
.profile-website-niche-active{
    background: var(--secondary-clr);
    color: var(--main-clr);
}
.profile-website-niche:hover{
    background: var(--main-light);
}
.profile-website-subniche, .profile-website-subniche-active{
    position: relative;
    height: max-content;
    border: 1px solid var(--border-clr);
    background: var(--main-light);
    color: var(--font-clr);
    border-radius: 25px;
    padding: 0.5em 1em;
    cursor: pointer;
}
.profile-website-subniche-active{
    background: var(--secondary-clr);
    color: var(--main-clr);
}
.profile-website-subniche input, .profile-website-subniche-active input, .profile-website-niche input, .profile-website-niche-active input{
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.profile-website-subniche label, .profile-website-subniche-active label{
    font-size: 14px;
    user-select: none;
}
.profile-website-niches-warning{
    position: fixed;
    background: rgba(0, 0, 0, 0.796);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-website-niches-warning > div{
    width: 40%;
    background: var(--main-clr);
    padding: 2em;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}
.profile-website-niches-warning > div p{
    text-align: left;
    line-height: 1.5em;
}
.profile-website-niches-warning > div button{
    width: max-content;
    background: transparent;
    border: none;
    color: var(--secondary-clr);
    font-weight: 500;
    cursor: pointer;
}
.profile-website-niches-warning > div button:hover{
    color: var(--intense-clr);
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .profile-website-languages, .profile-website-age, .profile-website-niche-wrapper > div{
        align-items: flex-start;
    }
    .profile-website-languages, .profile-website-age{
        width: 100%;
        height: 100vh;
    }
    .profile-website-age > div{
        width: 100%;
    }
    .profile-website-languages h1, 
    .profile-website-age h1, 
    .profile-website-niche-wrapper > div > h1, 
    .profile-website-languages p, 
    .profile-website-age p, 
    .profile-website-niche-wrapper > div > p{
        text-align: left;
    }
    .profile-website-languages-options{
        padding: 0;
    }
    .profile-websites-languages-save-btn{
        width: 100%;
    }
    .profile-websites-languages-close-btn{
        right: 0.5em;
        top: 0.5em;
    }

}
.feedback{
    width: 100%;
    padding: 6em 0em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5em;
}
.feedback-open-btn{
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    text-decoration: none;
    background: var(--secondary-clr);
    border: none;
    padding: 1em 0.5em;
    color: var(--main-clr);
    cursor: pointer;
}
.feedback-open-btn:hover{
    background: var(--intense-clr);
}

.feedback h1{
    width: 70%;
    font-weight: 400;
    font-size: 2rem;
    text-align: left;
}
.feedback p, label{
    color: var(--font-clr);
}
.feedback label{
    width: 70%;
    text-align: left;
    font-size: 1.2rem;
}

.feedback-rating{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.feedback-rating > div{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.feedback-rating-score-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.feedback-rating-score-active{
    background: red;
    color: var(--main-clr);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.feedback-rating-score-active{
    background: var(--secondary-clr);
}
.feedback-rating-score-desc{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
}


.feedback-comments{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.feedback-comments textarea{
    width: 100%;
    background: transparent;
    border: 1px solid var(--border-clr);
    resize: none;
}
.feedback-comments button{
    background: var(--secondary-clr);
    border: none;
    border-radius: 5px;
    color: var(--main-clr);
    padding: 1em 2em;
    width: max-content;
}
.profile-profile{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4em;
    padding: 1em 2em 5em 2em;
    position: relative;
    background: var(--main-light);
}


/* Header */
.profile-header{
    height: max-content;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.profile-header div{
    width: 60%;
}
.profile-header div h1{
    font-size: 1.5rem;
    text-align: left;
    color: var(--font-clr);
    font-weight: normal;
}
.profile-header div p{
    text-align: left;
    color: var(--font-light);
    font-size: 14px;
    line-height: 20px;
}
.profile-header img{
    position: absolute;
    right: 1em;
    width: 35%;
    height: 35%;
}


/* Pages */
.profile-pages-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4em;
    padding: 1em;
}



/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .profile-profile{
        padding: 1em;
    }
    .profile-header div{
        width: 100%;
    }
    .profile-pages-wrapper{
        padding: 0;
    }

    .profile-info-details-divs-websites span{
        overflow: hidden;
        width: 10%;
    }
    .profile-info-details-divs-websites div{
        opacity: 0;
    }
}
.profile-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3em;
}

.profile-info-details{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.profile-info-details-title{
    text-align: left;
    margin: 0;
    color: var(--font-clr);
    font-size: 18px;
    font-weight: 500;
}
.profile-info-details-sub{
    text-align: left;
    color: var(--font-light);
    margin: 0;
    font-size: 14px;
}
.profile-info-details-divs{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1em;
    margin-top: 1em;
}
.profile-info-details-divs > div{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3em;
    padding: 1em;
    color: var(--font-light);
    font-size: 0.8rem;
    border-bottom: 1px solid var(--border-clr);
}
.profile-info-details-divs-div > div span{
    color: var(--font-light);
    width: 20%;
    text-align: left;
}
.profile-info-details-divs > div p{
    width: 80%;
    text-align: left;
    color: var(--font-clr);
    font-weight: 500;
}
.profile-info-details-divs > div:nth-child(1) p{
    text-transform: capitalize;
}
.profile-info-details-divs > div ul{
    display: flex;
    align-items: flex-start;
    width: 80%;
    padding: 0;
    gap: 1em;
}
.profile-info-details-divs > div ul li{
    text-align: left;
    list-style-type: none;
    color: var(--font-clr);
    font-weight: 500;
}
.profile-info-details-divs > div > div{
    position: absolute;
    display: flex;
    align-items: center;
    gap: 0.5em;
    right: 3em;
}
.profile-info-details-edit-btn{
    font-size: 18px;
    border: none;
    background: transparent;
    color: var(--secondary-clr);
    cursor: pointer;
}
.profile-info-details-add-btn{
    background: transparent;
    color: var(--secondary-clr);
    border: none;
    padding: 0;
}

.support{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 6em 0;
    gap: 4em;
}
.support img{
    width: 40%;
}


.support-search{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.support-search img{
    width: 2em;
    height: 2em;
}
.support-search h5{
    font-weight: 400;
    text-align: center;
    font-size: 1.5rem;
    color: var(--secondary-clr);
    margin: 0;
}
.support-search p{
    font-size: 0.8rem;
    text-align: left;
    color: var(--font-light);
}
.support-search-input-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: var(--material-shadow);
    border-radius: 5px;
}
.support-search-input{
    width: 100%;
    border: 1px solid var(--border-clr);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em;
}
.support-search-input input{
    background: transparent;
    width: 90%;
    padding: 1em;
    border: none;
    outline: none;
}
.support-search-input-results{
    background: var(--main-clr);
    width: 100%;
    min-height: 100px;
    position: absolute;
    top: 50px;
    border-radius: 5px;
    box-shadow: var(--material-shadow);
}
.support-search-input-results p{
    padding: 0.5em 1em;
}

.support-faqs{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.support-faqs h5{
    text-align: left;
    font-weight: 400;
    text-align: left;
    margin: 0;
    font-size: 1rem;
}
.support-faqs-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.support-faqs-single{
    border-radius: var(--radius);
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    display: flex;
    justify-content: space-between;
    padding: 1em;
    gap: 1em;
    color: var(--secondary-clr);
    cursor: pointer;
    transition: all 0.3s;
}
.support-faqs-single:hover{
    background: var(--secondary-clr);
    color: var(--main-clr);
}
.support-faqs-single-link{
    background: transparent;
    color: var(--secondary-clr);
    border: none;
    transform: rotate(90deg);
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .support{
        padding: 6em 1em 1em 1em;
    }
    .support-search{
        width: 100%;
    }
    .support img{
        width: 100%;
    }
    .support-search img{
        width: 2em;
        height: 2em;
    }
    .support-faqs{
        width: 100%;
    }
}
.profile-wrapper{
    position: relative;
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    padding-top: 10vh;
}


.profile-nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0em 1em;
    background: white;
    border-bottom: 1px solid var(--border-clr);
    z-index: 10;
}
.logo{
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.logo img{
    width: 1em;
    height: 1em;
}
.logo h1{
    color: var(--secondary-clr);
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 600;
}
.profile-nav-user{
    display: flex;
    align-items: center;
    gap: 0.5em;
}


.profile-content-wrapper{
    position: relative;
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background: var(--main-clr);
    overflow-x: auto;
}


/* Profile dashboard */
.profile-dashboard{
    width: 81%;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

/* Universal dashboard styles */
.profile-dashboard-wrappers{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em;
    gap: 1em;
}

.profile-dashboard-title{
    font-size: 18px;
    color: var(--font-clr);
    text-align: left;
    margin: 0;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    .profile-dashboard{
        width: 100%;
    }
}

@media only screen and (max-width: 480px){
    .logo h1{
        display: none;
    }
    .profile-dashboard{
        width: 100%;
    }
}
/* Input */
.form-input-text, .form-input-date{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5em;
    border: 1px solid var(--border-clr);
    border-radius: 5px;
    padding: 0 1em;
    color: var(--font-clr);
}
.form-input-text:focus-within, .form-input-date:focus-within{
    border-bottom: 2px solid var(--secondary-clr);
}
.form-input-text input{
    width: 90%;
    border: none;
    padding: 1em;
    outline: none;
}


/* Date input */
.form-input-date div{
    width: 90%;
    display: flex;
    align-items: center;
}
.form-input-date input{
    width: 15%;
    border: none;
    padding: 1em 0.5em;
    outline: none;
}
#form-input-date-year{
    width: 30%;
}
.form-input-date input::-webkit-outer-spin-button,
.form-input-date input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-input-date input[type=number] {
  -moz-appearance: textfield;
}

/* Textarea */
.textarea{
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 0.5em;
    border: 1px solid var(--border-clr);
    border-radius: 5px;
    padding: 1em;
    color: var(--font-clr);
}
.textarea:focus-within{
    border-bottom: 2px solid var(--secondary-clr);
}
.textarea textarea{
    width: 90%;    
    background: transparent;
    outline: none;
    border: none;
    padding: 0 1em;
    height: 100px;
    resize: none;
}

/* File input */
input[type="file"]{
    display: none;
}
.input-file{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    border: 1px solid var(--border-clr);
    border-radius: 5px;
    overflow: hidden;
}
.input-file > p{
    font-size: 0.9rem;
    background: var(--main-light);
    height: 100%;
    color: var(--font-light);
    padding: 1em;
}
.input-file > span {
    height: 100%;
    background: var(--main-clr);
    color: var(--secondary-clr);
    font-size: 0.9rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 1em;
    border-left: 1px solid var(--border-clr);
}

/* Select option */
.form-options{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    color: var(--font-clr);
    border: 1px solid var(--border-clr);
    border-radius: 5px;
    padding-left: 1em;
}
.form-options:focus-within{
    border-bottom: 2px solid var(--secondary-clr);
}
.form-options select{
    width: 100%;
    padding: 1em 0;
    color: var(--font-light);
    margin: 0;
}
.form-options option{
    font-size: 1rem;
    color: var(--font-clr);
}

/* Button */
.submit-button{
    width: 100%;
    background: var(--secondary-clr);
    border: none;
    border-radius: var(--radius);
    color: white;
    padding: 1em 2em;
    cursor: pointer;
    transition: background 0.3s;
    will-change: background;
}
.submit-button:hover{
    background: var(--intense-clr);
}


.search{
    position: relative;
    width: 100%;
    padding: 0.5em;
    background: var(--main-clr);
    border-radius: var(--radius);
    border: 1px solid var(--border-clr);
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 1em;
}
.search input{
    width: 90%;
    border: none;
    outline: none;
    background: transparent;
}
.search:focus-within{
    background: var(--main-light);
}
.search button{
    background: transparent;
    border: none;
    font-size: 1.1rem;
    color: var(--secondary-clr);
    cursor: pointer;
}

.checkbox-option{
    display: flex;
    align-items: center;
    gap: 0.5em;
    width: max-content;
    font-size: 0.8em;
}
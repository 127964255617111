#waitlist-form{
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 40%;
    margin-top: 1em;
}

.waitlist-form-error, .waitlist-form-success{
    color: var(--fail-clr);
    text-align: left;
    font-size: 0.9rem;
    margin: 0;
}
.waitlist-form-success{
    color: var(--success-clr);
}

#waitlist-form h6{
    font-weight: 300;
    font: 0.8rem;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    #waitlist-form{
        width: 60%;
    }
}
@media only screen and (max-width: 480px){
    #waitlist-form{
        width: 100%;
    }
}
.billing{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em 2em 5em 1em;
    gap: 1em;
}
.billing h1{
    font-weight: 400;
    font-size: 1.2rem; 
    text-align: left;
    margin: 0;
}


.billing-nav{
    display: flex;
    align-items: center;
}
.billing-nav-btn, .billing-nav-btn-active{
    background: transparent;
    border: none;
    padding: 0.5em 1em;
    border-radius: 15px;
    cursor: pointer;
}
.billing-nav-btn-active{
    background: var(--secondary-clr);
    color: var(--main-clr);
}


.billing-overview, .billing-incoices, .payment-details{
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    gap: 1em;
}


/* Invoices */
.billing-invoices-list{
    background: var(--main-clr);
}
.billing-invoices-list-top{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    border: 1px solid var(--border-clr);
    border-radius: var(--radius) var(--radius) 0 0;
    background: var(--main-clr);
}
.billing-invoices-list-top div{
    padding: 0.3em 0.6em;
    font-size: 0.8rem;
    background: var(--main-clr);
    text-align: left;
}
.billing-invoices-list-empty{
    background: var(--main-clr);
    padding: 0.5em;
    text-align: left;
    font-size: 0.9rem;
    color: var(--font-light);
}


/* Status page */
.billing-status-intent{
    padding: 4em;
    display: flex;
    flex-direction: column;
}
.billing-status-intent h1{
    font-size: 2rem;
    color: var(--font-title-clr);
    text-align: left;
}
.billing-status-intent > div{
    display: flex;
    align-items: center;
    gap: 1em;
}
.billing-status-intent-link{
    width: max-content;
    color: var(--secondary-clr);
}
.billing-status-intent-link:nth-child(2){
    background: var(--secondary-clr);
    padding: 0.5em;
    color: var(--main-clr);
    border-radius: 5px;
    text-decoration: none;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .billing{
        padding: 1em 1em 5em 1em;
    }
    .billing-plan-overview, .billing-details{
        padding: 1em;
    }
    .billing-details-content small{
        font-size: 0.8rem;
    }
}
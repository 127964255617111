.register, .finish-registration{
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    padding: 6em 0;
}

.register-content-wrapper{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding: 2em;
    box-shadow: var(--material-shadow);
    border-radius: 15px;
}
.register-content-wrapper h5{
    margin: 0;
    font-size: 2rem;
    line-height: 1.1em;
    color: var(--font-title-clr);
    text-align: left;
    font-weight: 300;
}
.register-content-wrapper > span{
    font-size: 0.9rem;
    color: var(--font-landing-clr);
    text-align: left;
    width: 100%;
}
.register-login-btn{ 
    text-decoration: none;
    text-align: left;
}
.register-form-p, .finish-registration p{
    font-size: 0.9rem;
    line-height: 1.5em;
    text-align: left;
    margin: 0;
    color: var(--font-landing-clr);
}
.register-terms{
    font-size: 0.8rem;
    color: grey;
    text-align: left;
    width: 100%;
}
.register-email-error{
    text-align: left;
    font-size: 0.8rem;
    width: 100%;
    margin: 0;
    color: var(--fail-clr);
}

/* Register form */
.register-form-wrapper{
    width: max-content;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    box-shadow: var(--material-shadow);
}
#register-form, #finish-registration-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin: 2em 0;
}
.finish-registration-name{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.register-form-input{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5em;
    border: 1px solid var(--border-clr);
    border-radius: 5px;
    padding: 0 1em;
}
.register-form-input:focus-within{
    border-bottom: 2px solid var(--secondary-clr);
}
.register-form-input input{
    width: 90%;
    border: none;
    padding: 1em;
    outline: none;
}
.register-icons{
    width: 5%;
    color: var(--font-clr);
}


.register-btn{
    width: 100%;
    background: var(--secondary-clr);
    border: none;
    border-radius: var(--radius);
    color: white;
    padding: 1em 2em;
    cursor: pointer;
    transition: background 0.3s;
    will-change: background;
}
.register-btn:hover{
    background: var(--intense-clr);
}
.register-btn:disabled{
    background: rgb(155, 155, 255);
    color: white;
    pointer-events: none;
    user-select: none;
}
.register-btn-disabled{
    width: 100%;
    background: rgb(155, 155, 255);
    border: none;
    border-radius: var(--radius);
    color: white;
    padding: 1em 2em;
    pointer-events: none;
}


/* Register await confiramtion page */
.register-await-confirmation{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
}
.register-await-confirmation h1{
    color: var(--font-title-clr);
    text-align: left;
    width: 50%;
    font-weight: 400;
    margin: 0;
}
.register-await-confirmation p{
    width: 50%;
    color: var(--font-landing-clr);
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.3em;
    font-weight: 300;
    margin: 0;
}
.register-await-confirmation small{
    text-align: left;
    width: 50%;
    color: var(--font-light);
}
.register-await-confirmation > div{
    width: 50%;
    display: flex;
    align-items: center;
    gap: 1em;
    margin-top: 1em;
}
.register-await-confirmation > div > button{
    border: 1px solid var(--secondary-clr);
    padding: 0.5em 1em;
    font-size: 1rem;
    border-radius: var(--radius);
    cursor: pointer;
    transition: background 0.2s;
    will-change: background;
}
.register-await-confirmation > div > button:nth-child(1){
    background: var(--secondary-clr);
    color: var(--main-clr);
}
.register-await-confirmation > div > button:nth-child(1):hover{
    background: var(--intense-clr);
}
.register-await-confirmation > div > button:nth-child(2){
    border-color: var(--border-clr);
    color: var(--font-clr);
}
.register-await-confirmation > div > button:nth-child(2):hover{
    background: rgb(224, 224, 224);
}

/* MEDIA QUERIES */
@media only screen and (max-width: 480px){
    .register-wrapper{
        padding: 6em 1em 1em 1em;
    }
    .register-content-wrapper{
        width: 100%;
    }
    .register-form-wrapper{
        width: 100%;
    }
    .register-wrapper h5{
        text-align: center;
        width: 100%;
    }
    .register-form-p{
        text-align: center;
        width: 100%;
    }
    .register-promo{
        display: none;
    }
    #register-form{
        width: 100%;
    }

    .finish-registration-content{
        width: 100%;
    }
    .finish-registration-content h5, .finish-registration-content p{
        width: 100%;
        text-align: left;
    }
    #finish-registration-form{
        width: 100%;
    }


    .register-await-confirmation{
        padding: 1em;
    }
    .register-await-confirmation h1, .register-await-confirmation p, .register-await-confirmation-info, .register-await-confirmation div{
        width: 100%;
    }

    .finish-registration{
        padding: 5em 1em 1em 1em;
    }
}
.register-payment{
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--main-light);
}
.register-payment-content{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.register-payment-content > div:nth-child(1){
    position: fixed;
    top: 0;
    left: 0;
    width: 40%;
    height: 100vh;
    overflow: hidden;
}
.register-payment-content > div:nth-child(1) img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.checkout-form{
    width: 60%;
    height: max-content;
    padding: 4em;
    display: flex;
    flex-direction: column;
    gap: 2em;
    border-radius: var(--radius);
    font-size: 0.8rem;
    color: var(--font-light);
}
.checkout-form-warning{
    background: var(--secondary-clr);
    padding: 2em;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 1em;
}
.checkout-form-warning > div{
    width: 10%;
    color: var(--main-clr);
    font-size: 3rem;
}
.checkout-form-warning p{
    width: 90%;
    text-align: left;
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--main-light);
    line-height: 1.5em;
}
.checkout-form-warning span{
    color: var(--main-clr);
    font-weight: 600;
}
#copy-btn{
    position: relative;
    width: max-content;
    border-radius: 5px;
    background: transparent;
    text-decoration: underline;
    color: var(--main-clr);
    font-weight: 600;
    font-size: 1rem;
    padding: 0;
    display: inline-block;
    cursor: pointer;
    border: none;
}
#copy-btn:hover::after{
    opacity: 1;
}
#copy-btn::after{
    content: "Copy text";
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--main-clr);
    color: var(--font-clr);
    border-radius: 5px;
    white-space: nowrap;
    padding: 0.5em;
    user-select: none;
    pointer-events: none;
    opacity: 0;
}


.checkout-form form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    box-shadow: var(--material-shadow);
    background: var(--main-clr);
    border-radius: 15px;
    padding: 4em;
}
.checkout-form-wrapper{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.checkout-form-wrapper h4{
    text-align: left;
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--font-title-clr);
}
.checkout-form form small{
    width: 100%;
    text-align: left;
    color: var(--font-light);
    font-size: 0.9rem;
}
.checkout-form form label{
    text-align: left;
    color: var(--font-landing-clr);
    font-size: 0.9rem;
    margin-bottom: 0.5em;
}
.checkout-form input{
    border: 1px solid var(--border-clr);
    border-radius: 5px; 
    padding: 1em;
}
.checkout-form input:disabled{
    background: var(--main-light);
}
.checkout-form-submit-btn{
    width: 100%;
    border: none;
    background: var(--secondary-clr);
    padding: 1em;
    border-radius: 5px;
    color: var(--main-clr);
    cursor: pointer;
}
.checkout-form-submit-btn:hover{
    background: var(--intense-clr);
}
.checkout-form-submit-btn:disabled{
    background: rgb(181, 178, 245);
    pointer-events: none;
    user-select: none;
}
.checkout-form-total{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.checkout-form-total p{
    color: var(--font-landing-clr);
}
.checkout-form-total small{
    color: var(--font-light);
}


/* Checkout Complete */
.register-payment-success{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
    padding: 4em;
}
.register-payment-success span{
    position: absolute;
    z-index: -1;
    font-size: 10rem;
    font-weight: 800;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    white-space: nowrap;
    color: var(--main-light);
}
.register-payment-success img{
    width: 25%;
    margin-bottom: 2em;
}
.register-payment-success h1{
    color: var(--font-title-clr);
    font-weight: 500;
    margin: 0;
}
.register-payment-success p{
    color: var(--font-landing-clr);
    margin: 0;
    width: 60%;
}
.register-payment-success-link{
    width: max-content;
    background: var(--secondary-clr);
    color: var(--main-clr);
    border-radius: var(--radius);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em 1em;
    margin-top: 1em;
    cursor: pointer;
}
.register-payment-success-link:hover{
    background: var(--intense-clr);
}


/* Payment failure */
.register-payment-failure{
    width: 100%;
    height: 100vh;
    padding: 8em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.register-payment-failure h1, .register-payment-failure p, .register-payment-failure small{
    text-align: left;
    color: var(--font-title-clr);
    margin: 0;
}
.register-payment-failure p{
    color: var(--font-landing-clr);
    margin-bottom: 1em;
}
.register-payment-failure small{
    color: var(--font-light);
}
.register-payment-link{
    font-weight: 500;
    color: var(--secondary-clr);
    font-size: 1rem;
    text-decoration: none;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 480px){
    .register-payment{
        padding: 1em 1em 8em 1em;
    }
    .register-payment-content > div:nth-child(1){
        display: none;
    }
    .checkout-form{
        width: 100%;
        padding: 0em;
    }
    .checkout-form form{
        padding: 2em;
    }

    .checkout-form-warning > div{
        font-size: 1.5rem;
    }

    .register-payment-success{
        padding: 2em;
        align-items: flex-start;
    }
    .register-payment-success img{
        width: 100%;
    }
    .register-payment-success span{
        font-size: 5rem;
    }
    .register-payment-success p, h1{
        width: 100%;
        text-align: left;
    }
}
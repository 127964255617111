.backlinks-table-nav{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.backlinks-table-nav-options{
    display: flex;
    align-items: center;
}

.backlinks-table-nav-options-single, .backlinks-table-nav-options-single-active{
    border: none;
    border-radius: 15px;
    color: var(--font-clr);
    background: var(--main-light);
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em 1em;
    cursor: pointer;
}
.backlinks-table-nav-options-single:hover{
    color: var(--secondary-clr);
}
.backlinks-table-nav-options-single-active{
    color: var(--main-clr);
    background: var(--secondary-clr);
}

#backlinks-table-nav-options-pending-message{
    color: var(--secondary-clr);
    font-size: 0.8rem;
    margin: 0;
    transform: translateY(-1px);
    padding: 0 1em;
}

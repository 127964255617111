.existing-users-toolbar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
}
.existing-users-toolbar-btns{
    display: flex;
    align-items: center;
    gap: 1em;
}
.existing-users-toolbar-btns button{
    background: var(--main-light);
    border: none;
    border-radius: 5px;
    padding: 0.5em 1em;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
}
.existing-users-toolbar-btns button:hover{
    background: var(--third-clr);
    color: var(--main-clr);
}
.existing-users-toolbar-sort{
    width: max-content;
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-size: 0.8rem;
}
.existing-users-toolbar-sort p{
    width: max-content;
    white-space: nowrap;
    color: var(--font-light);
    font-size: 0.8rem;
}
.existing-users-toolbar-sort select{
    border: 1px solid var(--border-clr);
    border-radius: 5px;
    font-size: 0.8rem;
    padding: 0.5em;
}


.existing-users-titles{
    border-radius: 0;
    box-shadow: none;
}
.existing-users-titles div, .existing-users-single-content div{
    width: 10%;
    padding: 0.5em;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
}
.existing-users-titles div:nth-child(1){
    width: 15%;
}
.existing-users-titles div:nth-child(3){
    width: 25%;
}
.existing-users-titles div:nth-child(5){
    width: 20%;
}
.existing-users-single-content div:nth-child(1){
    width: 15%;
}
.existing-users-single-content div:nth-child(3){
    width: 25%;
}
.existing-users-single-content div:nth-child(5){
    width: 20%;
}
.existing-users-single-dr{
    color: var(--intense-clr);
    font-weight: 500;
}
.existing-users-single-dr-spam{
    color: var(--fail-clr);
}
.existing-user-single-add-btn{
    background: transparent;
    border: none;
    color: var(--success-clr);
    cursor: pointer;
    font-size: 1.1rem;
}
.new-users{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.new-users-empty{
    padding: 1em;
    border: 1px solid var(--border-clr);
    border-top: none;
}
.new-users-empty p{
    color: var(--secondary-clr);
    font-size: 0.9rem;
    text-align: left;
}


.new-users-table{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}
.new-users-titles{
    width: 100%;
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    border: 1px solid var(--border-clr);
    display: flex;
    align-items: center;
    color: var(--font-light);
    border-radius: var(--radius) var(--radius) 0 0;
}


.new-users-single{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.new-users-single-content{
    position: relative;
    width: 100%;
    background: var(--main-clr);
    border-bottom: 1px solid var(--border-clr);
    border: 1px solid var(--border-clr);
    display: flex;
}
.new-users-titles div, .new-users-single-content div{
    width: 20%;
    padding: 0.6em;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
}
.new-users-titles div:last-child, .new-users-single-content div:last-child{
    width: 10%;
}
.new-users-titles div:hover, .new-users-single-content:hover{
    background: var(--main-light);
    cursor: pointer;
}
.new-user-single-status-green, .new-user-single-status-red{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--success-clr);
}
.new-user-single-status-red{
    background: var(--fail-clr);
}
.new-users-single-link{
    position: relative;
}
.new-users-single-link-options{
    position: absolute;
    top: -35px;
    left: 0;
}
.new-users-single-link-options button{
    width: 30px;
    height: 30px;
    background: var(--third-clr);
    border: 1px solid var(--third-clr);
    border-radius: 50%;
    color: var(--secondary-clr);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}
.new-users-single-link-options button:hover{
    background: var(--secondary-clr);
    color: var(--third-clr);
}
.new-users-titles div p, .new-users-single div p{
    text-align: left;
    color: var(--font-light);
    font-size: 14px;
}
.new-users-single div p{
    color: var(--font-clr);
}
.new-users-single div a{
    font-size: 14px;
    color: var(--secondary-clr);
    text-decoration: none;
}
.new-users-icon{
    color: var(--secondary-clr);
}
.new-users-single-actions-btn button{
    background: transparent;
    border: 1px solid var(--border-clr);
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3em;
}
.new-users-single-actions{
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 0.6em;
    background: var(--main-light);
}
.new-users-single-actions-dr-wrapper{
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.new-users-single-actions-dr-wrapper input{
    outline: none;
    background: var(--main-clr);
    border: none;
    border-radius: 5px;
    padding: 0.5em 1em;
}
.new-users-single-actions-dr-wrapper input::-webkit-outer-spin-button,
.new-users-single-actions-dr-wrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.new-users-single-actions-dr-wrapper input[type=number] {
    -moz-appearance: textfield;
}
.new-users-single-actions-dr-wrapper input:focus{
    border-bottom: 1px solid var(--secondary-clr);
}
.new-users-single-actions-btns{
    align-items: center;
    display: flex;
    gap: 0.5em;
}
.new-users-single-comment{
    text-align: left;
    color: var(--font-clr);
    font-size: 12px;
}
.new-users-single-actions-comment-btn{
    background: transparent;
    border: none;
    color: var(--secondary-clr);
    font-size: 1.1rem;
    cursor: pointer;
    transition: transform 0.3s;
}
.new-users-single-actions-comment-btn-disabled{
    background: transparent;
    border: none;
    color: var(--font-light);
    font-size: 1.1rem;
    pointer-events: none;
}
.new-users-single-actions-comment-btn:hover{
    transform: scale(1.2);
}
.new-users-single-actions-save-btn, .new-users-single-actions-spam-btn{
    background: var(--secondary-clr);
    color: var(--main-clr);
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.4em 0.8em;
    cursor: pointer;
    transition: background 0.3s;
}
.new-users-single-actions-spam-btn{
    background: var(--third-clr);
    color: var(--secondary-clr);
    border: 1px solid var(--border-clr);
}
.new-users-single-actions-save-btn:hover{
    background: var(--intense-clr);
}
.new-users-single-actions-spam-btn:hover{
    filter: brightness(90%);
}


/* New users comments */
.new-users-comments{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
    background: rgba(46, 43, 43, 0.825);
    display: flex;
    align-items: center;
    justify-content: center;
}
.new-users-comments-form{
    position: relative;
    width: 50%;
    padding: 1em;
    background: var(--main-clr);
    border-radius: var(--radius);
    box-shadow: var(--material-shadow);
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.new-users-comments-form h1{
    font-size: 1rem;
    text-align: left;
    margin: 0;
    color: var(--font-clr);
}
.new-users-comments-form p{
    text-align: left;
    margin: 0;
    color: var(--font-light);
    font-size: 0.9rem;
}
.new-users-comments-form textarea{
    margin-top: 2em;
    background: transparent;
    border: 1px solid var(--border-clr);
    outline: none;
    padding: 1em;
    resize: none;
}
.new-users-comments-form input{
    padding: 1em;
    outline: none;
    border: none;
}
.new-users-comments-form input:focus{
    border-bottom: 2px solid var(--secondary-clr);
}
.new-users-comments-submit-btn{
    width: max-content;
    background: var(--secondary-clr);
    border: none;
    color: var(--main-clr);
    padding: 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}
.new-users-comments-submit-btn:hover{
    background: var(--intense-clr);
}
.new-users-comments-close-btn{
    position: absolute;
    top: 1em;
    right: 1em;
    background: transparent;
    padding: 0.5em 1em;
    border-radius: 5px;
    border: 1px solid var(--secondary-clr);
    color: var(--secondary-clr);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}
.new-users-comments-close-btn:hover{
    background: var(--secondary-clr);
    color: var(--main-clr);
}

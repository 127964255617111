.contact{
    width: 100%;
    padding-top: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}
.contact-header{
    position: absolute;
    z-index: -1;
    top: 15vh;
    width: 100%;
    background: var(--secondary-clr);
    padding: 10em 15em; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-header h1{
    color: var(--main-clr);
    font-size: 3rem;
    text-align: center;
}
.contact-header p{
    color: var(--main-clr);
    font-size: 1.2rem;
    line-height: 1.5em;
    font-weight: 200;
}


.contact-form{
    width: 50%;
    background: white;
    box-shadow: var(--material-shadow);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 2em;
}
.contact-form p{
    font-size: 2rem;
    color: var(--font-clr);
    margin-bottom: 2em;
}
.contact-form small{
    color: var(--fail-clr);
    text-align: left;
}
.contact-form button{
    background: var(--secondary-clr);
    padding: 1em 2em;
    color: var(--main-clr);
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.contact-form button:hover{
    background: var(--intense-clr);
}
.contact-success{
    text-align: left;
    color: var(--intense-clr);
    font-size: 0.8rem;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
   
}

@media only screen and (max-width: 480px){
    .contact-header{
        padding: 10em 1em;
    }
    .contact-form{
        width: 100%;
        padding: 2em 1em;
    }
    .contact-form p{
        font-size: 1.5rem;
    }
}
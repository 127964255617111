/* Error */
.error, .error-chat{
    padding: 1em;
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 1em;
    color: var(--fail-clr);
}
.error-chat{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
}
.error-line{
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5em;
    color: var(--fail-clr);
}
.error p, .error-chat p, .error-line p{
    text-align: left;
    font-size: 0.9rem;
    width: 90%;
}

/* Loading spinner */
.spinner-wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.spinner {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    
    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear;
}
.dot1, .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: var(--secondary-clr);
    border-radius: 100%;
    
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}  
.dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}
@-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }} 
@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
}

/* Confirm action pop up */
.confirm-action{
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
}
.confirm-action-wrapper{
    width: 40%;
    background: var(--main-clr);
    border-radius: 5px;
    box-shadow: var(--material-shadow);
    display: flex;
    flex-direction: column;
    gap: 1em;
    overflow: hidden;
}
.confirm-action-wrapper-color{
    width: 100%;
    height: 5px;
}
.confirm-action-text, .confirm-action-btns{
    padding: 1em 2em;
    display: flex;
    align-items: center;
    gap: 1em;
}
.confirm-action-text-icon{
    color: var(--main-clr);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.confirm-action-text p{
    width: 90%;
    text-align: left;
    line-height: 1.5em;
    margin: 0;
}
.confirm-action-btns{
    justify-content: flex-end;
}
.confirm-action-btns button{
    background: var(--main-light);
    border-radius: 5px;
    padding: 0.5em 1em;
    border: 1px solid var(--border-clr);
    cursor: pointer;
}
.confirm-action-btns button:nth-child(2){
    color: var(--main-clr);
    border: none;
}
.confirm-action-btns button:hover{
    filter: brightness(90%);
}


/* Close modal button */
.close-modal-btn{
    position: absolute;
    top: 1em;
    right: 1em;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .confirm-action{
        padding: 1em;
    }
    .confirm-action-wrapper{
        width: 100%;
    }
}
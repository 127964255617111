.nav-waitlist{
    display: flex;
    align-items: center;
    gap: 1em;
    margin-right: 2em;
}

.waitlist-btn, .login-btn{
    padding: 0.5em 1em;
    border-radius: 5px;
    color: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
    background: var(--main-clr);
    text-decoration: none;
    font-size: 0.9rem;
    white-space: nowrap;
}
.waitlist-btn:hover{
    background: var(--intense-clr);
    color: var(--main-clr);
    border-color: var(--intense-clr);
}
.login-btn{
    position: relative;
    background: var(--secondary-clr);
    color: var(--main-clr);
}
.login-btn:hover{
    color: var(--third-clr);
}
.backlinks-page{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1.5em;
    background: var(--main-light);
    padding-bottom: 4em;
    border-left: 1px solid var(--border-clr);
}

.backlinks-top{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.backlinks-top h1{
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    color: var(--font-clr);
}
.backlinks-top h1 span{
    font-weight: 300;
}
.backlinks-top-search{
    padding: 0.4em 0.8em;
    border-radius: 25px;
    border: 1px solid var(--border-clr);
    outline: none;
    color: var(--font-clr);
    display: flex;
    align-items: center;
}
.backlinks-top-search:focus-within{
    box-shadow: var(--material-shadow);
}
.backlinks-top input{
    background: transparent;
    outline: none;
    border: none;
    padding: 0.4em 0.8em;
}
.backlinks-top-search button{
    background: transparent;
    border: none;
    padding: 0;
    color: var(--font-clr);
    font-size: 1rem;
    cursor: pointer;
}
.backlinks-top-search button:hover{
    color: var(--secondary-clr);
}


.backlinks-table{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
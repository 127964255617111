.not-found{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not-found h1{
    font-size: 2rem;
}
.not-found p{
    font-size: 1.5rem;
}


.no-results{
    width: 68%;
    height: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
}
.no-results div {
    width: 200px;
    height: 200px;
    overflow: hidden;
}
.no-results img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.no-results h1{
    font-size: 16px;
    color: var(--font-clr);
    width: 60%;
}
.no-results p{
    color: var(--font-clr);
    font-size: 0.9rem;
    width: 60%;
    line-height: 1.5em;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .no-results{
        width: 100%;
        height: 50vh;
        box-shadow: var(--material-shadow);
        border: 1px solid var(--border-clr);
    }
    .no-results h1, .no-results p{
        width: 100%;
    }
}
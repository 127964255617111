.backlinks{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em;
    gap: 1em;
}

.backlinks-unconfirmed{
    position: relative;
    width: 32%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    gap: 0.5em;
    background: var(--secondary-clr);
    border-radius: 5px;
    padding: 1em;
}
.backlinks-unconfirmed p{
    text-align: left;
    color: var(--main-clr);
    font-size: 0.8rem;
    line-height: 1.2em;
    margin: 0;
}
.backlinks-unconfirmed > div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.backlinks-unconfirmed-trust-rate-green{
    color: rgb(0, 255, 0);
}
.backlinks-unconfirmed-trust-rate-red{
    color: rgb(255, 104, 104);
}
.backlinks-unconfirmed > div small{
    font-weight: 400;
    font-size: 0.8rem;
    margin: 0;
}
.backlinks-unconfirmed > div h6{
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0;
    display: flex;
    align-items: flex-end;
    gap: 0.5em;
}
.backlinks-unconfirmed-icon{
    position: relative;
    color: var(--secondary-clr);
    font-size: 0.8rem;
}
.backlinks-unconfirmed-icon:hover::after{
    opacity: 1;
}
.backlinks-unconfirmed-icon::after{
    content: "This represents the rate of your reported and unconfirmed links";
    position: absolute;
    width: 150px;
    bottom: 25px;
    right: -80px;
    transform: translateX(-50%);
    background: var(--main-light);
    color: var(--font-light);
    font-size: 0.6rem;
    border-radius: 5px;
    padding: 0.5em;
    box-shadow: var(--material-shadow);
    opacity: 0;
}


.backlinks-category-bar button span{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--fail-clr);
    color: var(--main-clr);
    font-size: 0.7rem;
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}


.backlinks-single-actions-confirm-btn{
    background: var(--secondary-clr);
    color: var(--main-clr);
    border: none;
    border-radius: 5px;
    padding: 0.5em 1em;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
    transition: background 0.3s color 0.3s;
}
.backlinks-single-actions-confirm-btn:hover{
    background: var(--third-clr);
    color: var(--secondary-clr);
}
.backlinks-single-actions-confirm-btn[disabled]{
    background: var(--main-light);
    pointer-events: none;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .backlinks-unconfirmed{
        width: 100%;
    }
}
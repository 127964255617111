/* Finish registration page */
#finish-registration-form small{
    text-align: left;
    color: grey;
}
.finish-registration-name{
    display: flex;
}
.finish-registration-form-input{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;
    padding: 0 1em;
    border: 1px solid var(--border-clr);
    border-radius: 5px;
}
.finish-registration-form-input:focus-within{
    border-bottom: 2px solid var(--secondary-clr);
}
.finish-registration-form-input input, select {
    width: 90%;
    background: transparent;
    border: none;
    padding: 1em 2em;
    outline: none;
}
.finish-registration-form-input select{
    padding: 1em 0;
}
.finish-registration-form-input option{
    font-size: 1rem;
}
.finish-form-icon{
    color: var(--font-clr);
}


/* Registration failed */
.finish-register-failed{
    width: 100%;
    height: 100vh;
    padding: 8em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
}
.finish-register-failed h1, p{
    margin: 0;
    color: var(--font-title-clr);
}
.finish-register-failed img{
    width: 20%;
}
.finish-register-failed h1{
    display: flex;
    gap: 0.5em;
    font-weight: 400;
}
.finish-register-failed p{
    color: var(--font-landing-clr);
    width: 60%;
}
.finish-register-failed-email-error{
    color: var(--fail-clr);
    text-align: left;
}
.finish-register-failed button{
    width: max-content;
    border: none;
    background: transparent;
    padding: 0;
    font-weight: 600;
    color: var(--secondary-clr);
    text-decoration: underline;
    font-size: 1rem;
    cursor: pointer;
}
.finish-register-failed button:disabled{
    color: grey;
    user-select: none;
    pointer-events: none;
}
.finish-register-failed button:hover{
    color: var(--intense-clr);
}
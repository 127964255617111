.backlink-options{
    position: fixed;
    z-index: 10;
    bottom: 1em;
    right: calc(28% + 5em);
    width: calc(62% - 1em);
    height: calc(100% - 2em);
    background: var(--main-clr);
    border: 1px solid var(--border-clr);
    box-shadow: var(--material-shadow-double);
    border-radius: 15px;
    padding: 4em;
    display: flex;
    flex-direction: column;
    gap: 2em;
    transform-origin: top right;
}

.backlink-options-collapsed{
    position: fixed;
    z-index: 10;
    top: 1em;
    right: calc(28% + 4em);
    width: 50px;
    height: 50px;
    background: var(--main-clr);
    color: var(--secondary-clr);
    box-shadow: var(--material-shadow-double);
    font-size: 24px;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}
.backlink-options-collapsed:hover{
    transform: scale(1.2);
    background: var(--main-light);
}

/* Title */
.backlink-options-title{
    width: 100%;
    position: relative;
    display: flex;
}
.backlink-options h1{
    color: var(--font-clr);
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.backlink-options-title > div{
    position: absolute;
    width: 300px;
    left: 150px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.8rem;
    background: var(--font-clr);
    border-radius: 5px;
    color: var(--main-clr);
    padding: 1em;
    text-align: left;
}

.backlink-options-content{
    display: flex;
    gap: 1em;
}
.backlink-options-form-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.backlink-options-form-wrapper h5{
    text-align: left;
    margin: 0;
    font-weight: 500;
    color: var(--font-clr);
}


.backlink-options-close-btn{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border: 1px solid var(--border-clr);
    background: var(--secondary-clr);
    color: var(--main-clr);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.5em;
}


@media only screen and (max-width: 980px){
   
}
@media only screen and (max-width: 480px){
    .backlink-options{
        width: 100%;
        right: 0em;
        top: 0;
        height: 100vh;
    }

    .backlink-options-collapsed{
        right: calc(50px + 2em);
        bottom: 1em;
    }
}
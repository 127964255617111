.landing{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-section-title{
    width: max-content;
    color: var(--intense-clr);
    font-size: 0.8rem;
    background: var(--third-clr);
    border-radius: 5px;
    padding: 0.5em 1em;
}


.landing-join-btn{
    width: max-content;
    text-decoration: none;
    background: var(--secondary-clr);
    border: none;
    border-radius: 25px;
    padding: 1em 2em;
    font-size: 0.9rem;
    color: var(--main-clr);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.landing-join-btn:hover{
    background: var(--intense-clr);
}


/* Landing About */
.about{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.about-content{
    width: 100%;
    padding: 4em 8em;
    display: flex;
    flex-direction: column;
    gap: 3em;
    background: var(--secondary-clr);
}
.about h1{
    text-align: left;
    color: var(--main-clr);
    font-size: 3rem;
    width: 60%;
    font-weight: 500;
    line-height: 1.2em;
    margin: 0;
    font-family: 'Work Sans', sans-serif;
}
.about-text{
    display: flex;
    gap: 2em;
}
.about-text p{
    width: 50%;
    text-align: left;
    color: var(--main-light);
    font-size: 0.9rem;
    line-height: 1.5em;
}
.about-link{
    text-decoration: none;
    font-size: 1rem;
    width: max-content;
    padding: 0.5em 1em;
    border: none;
    background: var(--main-clr);
    color: var(--secondary-clr);
    border-radius: 5px;
}
.about-link:hover{
    background: var(--third-clr);
}

@media only screen and (max-width: 980px){
    .about-content{
        padding: 4em;
    }
    .about h1{
        width: 100%;
    }
}
@media only screen and (max-width: 480px){
    .about-content{
        padding: 2em;
    }
    .about h1{
        width: 100%;
    }
    .about-text{
        flex-direction: column;
    }
    .about-text p{
        width: 100%;
    }
    #about-wave-1{
        transform: translateY(2px);
    }
    #about-wave-2{
        transform: translateY(-2px);
    }
}
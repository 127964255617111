/* Conversation chats */
.conversation-messages, .conversation-messages-open{
    position: fixed;
    z-index: 11;
    bottom: 6em;
    right: 4em;
    width: 28%;
    height: calc(100% - 7em);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background: var(--main-clr);
    border-radius: var(--radius);
    box-shadow: var(--material-shadow-double);
    border: none;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: all 0.1s;
}
.conversation-messages-open{
    transform: scaleY(1);
}
.conversation-messages-empty{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.conversation-messages-empty h1{
    font-weight: 300;
    color: var(--font-clr);
}
.conversation-messages-empty p{
    color: var(--font-clr);
}


/* Conversation chat single */
.conversation-messages-single{
    width: 100%;
    height: 100%;
    background: transparent;
}


.conversation-messages-single-top{
    position: relative;
    width: 100%;
    height: 12%;
    background: var(--secondary-clr);
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 1em;
}
.conversation-messages-single-top > div:nth-child(1){
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid var(--main-clr);
    color: var(--main-clr);
    display: flex;
    align-items: center;
    justify-content: center;
}
.conversation-messages-single-top > div:nth-child(2){
    color: var(--main-clr);
    font-size: 14px;
}
.conversation-messages-single-top > div:nth-child(2) span{
    color: var(--main-clr);
    font-weight: 500;
    text-transform: capitalize;
    font-size: 14px;
}
.conversation-messages-single-top > div:nth-child(3){
    position: absolute;
    right: 1em;
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: var(--main-clr);
}
.conversation-messages-single-top > div:nth-child(3) button{
    background: transparent;
    border: none;
    padding: 0;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-clr);
    cursor: pointer;
}


.conversation-messages-single-text, .conversation-messages-empty{
    position: relative;
    width: 100%;
    height: 76%;
    display: flex;
    flex-direction: column;
    background: var(--main-clr);
    padding: 1em;
}
.conversation-messages-single-text-title{
    height: 10%;
    font-weight: 300;
    color: var(--font-clr);
    font-size: 1rem;
    margin: 0;
    background: transparent;
}
.conversation-messages-single-text p{
    color: var(--font-clr);
}
.conversation-messages-single span{
    text-transform: capitalize;
    color: var(--secondary-clr);
}
.conversation-messages-single-messages{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    scroll-snap-type: y proximity;
}
.conversation-messages-single-messages::-webkit-scrollbar {
  width: 10px;
}
.conversation-messages-single-messages-scroll-btn{
    position: absolute;
    top: 1em;
}

/* Approval */
.conversation-messages-single-messages-approval{
    position: absolute;
    bottom: 1em;
    box-shadow: var(--material-shadow);
    padding: 1em;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    background: var(--intense-clr);
}
.conversation-messages-single-messages-approval p{
    font-size: 0.9rem;
    color: var(--main-clr);
    text-align: left;
}
.conversation-messages-single-messages-approval > div{
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.conversation-messages-single-messages-approval > div button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3em;
    background: var(--fail-clr);
    border: none;
    border-radius: 5px;
}
.conversation-messages-single-messages-approval > div button:nth-child(1){
    background: green;
}


/* Single messages */
.conversation-messages-single-messages-wrapper{
    display: flex;
    align-items: flex-start;
    gap: 0.3em;
}
.conversation-messages-single-messages-sender-wrapper, 
.conversation-messages-single-messages-reciever-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.2em;
}
.conversation-messages-single-messages-reciever-wrapper{
    align-items: flex-start;
    gap: 0.2em;
    width: 90%;
}


.conversation-messages-single-messages-sender-wrapper p {
    color: var(--main-clr);
    text-align: left;
    font-size: 12px;
    user-select: none;
}
.conversation-messages-single-messages-reciever-wrapper p{
    color: var(--font-clr);
    text-align: left;
    font-size: 12px;
    user-select: none;
}


.conversation-messages-single-messages-sender-message, 
.conversation-messages-single-messages-reciever-message{
    position: relative;
    max-width: 90%;
    background: var(--secondary-clr);
    padding: 0.5em;
    border-radius: var(--radius) 0 var(--radius) var(--radius);
    cursor: pointer;
}
.conversation-messages-single-messages-reciever-message{
    background: rgb(237, 233, 255);
    border-radius: 0 var(--radius) var(--radius) var(--radius) ;
}
.conversation-messages-single-messages-time{
    font-size: 10px;
    color: var(--font-light);
}

/* Automated messages */
.backlink-messages-automated{
    display: flex;
    gap: 0.5em;
    margin-bottom: 1em;
}
.backlink-messages-automated-icon{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--font-title-clr);
    color: var(--main-clr);
    display: flex;
    align-items: center;
    justify-content: center;
}
.backlink-messages-automated-message{
    width: 90%;
    background: var(--font-title-clr);
    padding: 0.5em 1em;
    border-radius: 10px;
}
.backlink-messages-automated-message p{
    font-size: 0.8rem;
    color: var(--main-clr);
    line-height: 1.5em;
    text-align: left;
}


/* Conversation form */
#conversation-messages-form{
    width: 100%;
    height: 12%;
    display: flex;
    align-items: center;
    padding: 1em;
    border-top: 1px solid var(--border-clr);
}
#conversation-messages-form input{
    border: none;
    outline: none;
    width: 90%;
    height: 100%;
}
.profile-conversation-send-btn{
    background: transparent;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    color: var(--main-clr);
    background: var(--secondary-clr);
    border-radius: 50%;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-conversation-send-btn:hover{
    background: var(--intense-clr);
}
.backlink-messages-form-error{
    height: 12%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    background: var(--main-light-plus);
    padding: 0.5em 1em;
}
.backlink-messages-form-error p{
    font-size: 0.9rem;
    color: var(--font-clr);
    margin: 0;
}
.backlink-messages-form-error button{
    background: var(--secondary-clr);
    color: var(--main-clr);
    border: none;
    border-radius: 5px;
    padding: 0.5em;
    cursor: pointer;
}
.backlink-messages-form-error button:hover{
    background: var(--intense-clr);
}

/* Pending */
.conversation-messages-single-pending{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.874);
    /* backdrop-filter: blur(5px); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    padding: 2em;
}
.conversation-messages-single-pending small{
    color: var(--font-clr);
}


/* Options */
.conversation-messages-single-messages-options{
    position: absolute;
    left: -30px;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
}
.conversation-messages-single-messages-options button{
    background: transparent;
    border: none;
    color: var(--secondary-clr);
    font-size: 1.2rem;
    cursor: pointer;
}
.conversation-messages-single-messages-options button:hover{
    color: var(--intense-clr);
    transform: scale(1.1);
}
.conversation-messages-single-messages-options button:disabled{
    color: grey;
    user-select: none;
    pointer-events: none;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    .conversation-messages, .conversation-messages-open{
        width: 90%;
        right: 1em;
    }
}

@media only screen and (max-width: 480px){
    .conversation-messages, .conversation-messages-open{
        width: 90%;
        right: 1em;
    }
}
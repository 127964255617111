.website-details-links{
    width: 100%;
}
.website-details-links h1{
    margin-bottom: 1em;
}
.website-details-links-list{
    background: var(--main-clr);
    max-height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
}
.website-details-links-titles, .website-details-links-single{
    display: flex;
    display: grid;
    grid-template-columns: 5% 30% 30% 17.5% 17.5%;
    text-align: left;
    padding: 0.5em;
    font-size: 0.8rem;
    border: 1px solid var(--border-clr);
}
.website-details-links-single{
    padding: 0.5em;
    font-size: 0.8rem;
}
.website-details-links-empty{
    width: 100%;
    background: var(--main-clr);
    padding: 1em;
}
.website-details-links-empty p{
    text-align: left;
    color: var(--font-light);
    font-size: 0.8rem;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}
@media only screen and (max-width: 480px){
    .website-details-links-titles, .website-details-links-single{
        grid-template-columns: 10% 35% 35% 20%;
    }
    .website-details-links-titles div:nth-child(5), .website-details-links-single div:nth-child(5){
        display: none;
    }
}
/* Wrapper for outlinks table */
.outlinks-all{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.outlinks-all h1{
    text-align: left;
    font-size: 1rem;
    color: var(--font-clr);
    font-weight: 500;
    margin: 0;
}
.outlinks-not-found{
    padding: 0.5em;
    border: 1px solid var(--border-clr);
    background: var(--main-clr);
}
.outlinks-not-found p{
    color: var(--secondary-clr);
    text-align: left;
    font-size: 14px;
}


/* Outlinks titles */
.outlinks-table-title{
    width: 100%;
    display: grid;
    grid-template-columns: 20% 20% 10% 20% 5% 12.5% 12.5%;
    border: 1px solid var(--border-clr);
    border-radius: var(--radius) var(--radius) 0 0;
    background: var(--main-clr);
}
.outlinks-table-title div {
    padding: 0.3em 0.6em;
    font-size: 0.8rem;
    background: var(--main-clr);
    text-align: left;
}


/* Single outlink */
.outlinks-single{
    display: grid;
    grid-template-columns: 20% 20% 10% 20% 5% 12.5% 12.5%;
    border: 1px solid var(--border-clr);
    background: var(--main-clr);
}
.outlinks-single > div{
    position: relative;
    font-size: 0.8rem;
    padding: 0.5em;
    display: flex;
    align-items: flex-end;
    gap: 0.5em;
}
.outlinks-single > div p{
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: context-menu;
}
.outlinks-single-cell-link{
    color: var(--secondary-clr);
    cursor: pointer;
    font-size: 1rem;
    margin: 0;
    transform: translateY(5px);
}
#outlinks-single-tooltip{
    position: absolute;
    background: var(--main-light);
    color: var(--font-clr);
    box-shadow: var(--material-shadow);
    border-radius: 5px;
    padding: 0.4em;
    top: -20px;
    left: 5px;
    font-size: 11px;
    z-index: 30;
}
.outlinks-single-url{
    color: var(--secondary-clr);
    font-weight: 500;
}
.outlinks-single-added, .outlinks-single-added-true{
    font-size: 0.7rem;
    color: rgb(197, 197, 0);
    font-weight: 600;
    border-radius: 5px;
}
.outlinks-single-added-true{
    color: rgb(0, 172, 0);
    font-weight: 600;
}


.outlinks-single-actions{
    display: flex;
    align-items: flex-start;
    gap: 0.5em;
}
.outlinks-single-actions-report-btn, .outlinks-single-actions-delete-btn{
    position: relative;
    background: transparent;
    border: 1px solid var(--border-clr);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
}
.outlinks-single-actions > button[disabled]{
    color: grey;
    border: none;
    pointer-events: none;
}
.outlinks-single-actions-report-btn:hover, .outlinks-single-actions-delete-btn:hover{
    background: var(--secondary-clr);
    color: var(--main-clr);
}
.outlinks-single-actions-confirm-btn{
    color: var(--success-clr);
}
.outlinks-single-actions-report-btn{
    color: var(--intense-clr);
}
.outlinks-single-actions-report-btn:hover::after{
    opacity: 1;
}
.outlinks-single-actions-report-btn::after{
    content: "Report removed link";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 35px;
    background: var(--secondary-clr);
    color: var(--main-clr);
    font-size: 0.8rem;
    border-radius: 5px;
    padding: 0.5em;
    width: 100px;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
}
.outlinks-single-actions-delete-btn{
    color: var(--fail-clr);
}
.outlinks-single-actions-delete-btn:hover::after{
    opacity: 1;
}
.outlinks-single-actions-delete-btn::after{
    content: "Delete this link";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 35px;
    background: var(--secondary-clr);
    color: var(--main-clr);
    font-size: 0.8rem;
    border-radius: 5px;
    padding: 0.5em;
    width: 100px;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
}


.outlinks-top-link{
    width: 100%;
    padding: 0.5em;
    border: 1px solid var(--border-clr);
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: var(--secondary-clr);
}
.outlinks-top-link p{
    text-align: left;
    font-size: 0.8rem;
    font-style: italic;
}
.faq{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding: 4em 8em;
}

.faq > p{
    text-align: left;
    font-size: 3rem;
    font-weight: 700;
    line-height: 0.9em;
    color: var(--font-title-clr);
    margin: 0 0 0.5em 0;
    font-family: 'Work Sans', sans-serif;
}


.faq-content{
    width: 100%;
    display: flex;
    gap: 2em;
}
.faq-questions{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}


.faq-single{
    display: flex;
    flex-direction: column;
    background: var(--main-light);
    border-radius: 15px;
    cursor: pointer;
}
.faq-single-question{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2em;
}
.faq-single-question h5{
    font-size: 1.3rem;
    font-weight: 400;
    margin: 0;
    color: var(--font-landing-clr);
    text-align: left;
    user-select: none;
}
.faq-single-answer-icon, .faq-single-answer-icon-open{
    font-size: 1.5rem;
    color: var(--font-landing-clr);
}
.faq-single-answer-icon-open{
    transform: rotateZ(180deg);
}


.faq-single-answer, .faq-single-answer-show{
    display: none;
    padding: 0 2em 2em 2em;
}
.faq-single-answer-show{
    display: flex;
}
.faq-single-answer-show p, .faq-single-answer p{
    text-align: left;
    color: var(--font-light);
    line-height: 1.5em;
    user-select: none;
}


@media only screen and (max-width: 980px){
    .faq{
        padding: 4em;
    }
}
@media only screen and (max-width: 480px){
    .faq{
        padding: 2em;
    }
    .faq > p{
        font-size: 2rem;
    }
    .faq-single-question h5{
        font-size: 1rem;
        width: 80%;
    }
}
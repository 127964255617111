.toast{
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    display: flex;
    align-items: center;
    gap: 1em;
    background: var(--main-clr);
    border: 1px solid rgb(231, 231, 231);
    border-radius: var(--radius);
    padding: 2em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 999;
}
.toast-line{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background: var(--success-clr);
}
.toast-icon{
    width: 10%;
    height: 100%;
    font-size: 25px;
    color: var(--success-clr);
    display: flex;
    align-items: center;
    justify-content: center;
}
.toast-text{
    position: relative;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 0.2em;
}
.toast-text p:nth-child(1){
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    margin: 0;
}
.toast-text p:nth-child(2){
    font-size: 0.9rem;
    color: var(--font-light);
    text-align: left;
    margin: 0;
}
.toast button{
    position: absolute;
    right: 1em;
    top: 1em;
    background: transparent;
    border: none;
    color: var(--font-clr);
    cursor: pointer;
}
.profile-sidebar-mobile{
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: flex-end;
    gap: 0.5em;
}

.profile-sidebar-mobile-btns{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 100%;
    height: 90vh;
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    border-radius: 15px 15px 0 0;
    padding-bottom: 1em;
}
.profile-sidebar-mobile-close-btn{
    width: 100%;
    height: 2em;
    border-radius: 15px 15px 0 0;
    border: none;
}
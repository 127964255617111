.website-overview{
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    padding: 1em 1em 1em 1em;
    gap: 1em;
    border-left: 1px solid var(--border-clr);
}
.website-overview-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.website-overview h1{
    color: var(--font-clr);
    text-align: left;
    margin: 0;
    font-size: 1.1rem;
    font-weight: 400;
}


.website-overview-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}
.website-overview-nav{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    padding: 1em 1em 0.5em 1em;
    border-radius: 5px;
    background: var(--main-clr);
}
.website-overview-nav-search{
    width: 30%;
}



.website-overview-list-wrapper{
    background: var(--main-clr);
    padding: 0.5em 1em 1em 1em;
}
.website-overview-list-top, .website-overview-list-items-single{
    width: 100%;
    display: grid;
    grid-template-columns: 10% 50% 20% 20%;
    border: 1px solid var(--border-clr);
    background: var(--main-clr);
}
.website-overview-list-top div, .website-overview-list-items-single div{
    text-align: left;
    padding: 0.5em 1em;
    color: var(--font-light);
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.website-overview-list-items-single div{
    color: var(--font-clr);
}
.website-overview-list-items-single-link{
    color: var(--secondary-clr);
    text-decoration: underline;
    text-align: left;
    padding: 0.5em 1em;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    margin: 0;
}
.website-overview-list-items-single-icon{
    color: var(--font-clr);
    font-size: 1rem;
}


.website-overview-list-items{
    display: flex;
    flex-direction: column;
}


.website-overview-list-items-empty{
    text-align: left;
    background: var(--main-clr);
    padding: 0.5em 1em;
    border: 1px solid var(--border-clr);
    font-size: 0.9rem;
    color: var(--font-light);
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .website-overview{
        padding: 0;
    }
    .website-overview-top{
        padding: 1em;
    }
    .website-overview-nav-search{
        width: 100%;
    }
}
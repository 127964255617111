/* Profile sidebar */
.profile-sidebar{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 16%;
    height: 90vh;
    background: var(--main-clr);
    padding: 1em 0;
}



.profile-sidebar-options{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.profile-sidebar-options-top, .profile-sidebar-options-bottom{
    display: flex;
    flex-direction: column;
    gap: 0.3em;
}
.profile-sidebar-options-top small{
    text-align: left;
    color: var(--font-light);
    font-size: 0.9rem;
    margin: 0 0 0.5em 1em;
}
.profile-sidebar-options-top-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
}
.profile-sidebar-options-top-header p{
    text-align: left;
    color: var(--font-clr);
    font-size: 0.8rem;
}
.profile-sidebar-options-top-header button{
    border-radius: 5px;
    background: var(--secondary-clr);
    border: none;
    color: var(--main-clr);
    cursor: pointer;
    padding: 0.5em 1em;
}
.profile-sidebar-options-top-header button:hover{
    background: var(--secondary-clr);
    color: var(--main-clr);
    border-color: var(--secondary-clr);
}
.profile-sidebar-icons{
    font-size: 1rem;
    font-weight: 300;
}


/* Sidebar option buttons */
.profile-sidebar-options-btn, .profile-sidebar-options-btn-active, .profile-sidebar-options-nested-btn, .profile-sidebar-options-nested-btn-active{
    width: 100%;
    text-decoration: none;
    position: relative;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em 0.5em 0.5em 1em;
    color: var(--font-light);
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s;
}
.profile-sidebar-options-btn:hover{
    background: var(--third-clr);
}
.profile-sidebar-options-btn-active{
    background: var(--secondary-clr);
    color: var(--main-clr);
}
.profile-sidebar-options-btn-icon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-sidebar-options-btn:disabled, .profile-sidebar-options-btn-active:disabled{
    background: transparent;
    color: grey;
    user-select: none;
    pointer-events: none;
}

.profile-sidebar-options-btn-notification{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2em;
    background: var(--fail-clr);
    color: var(--main-clr);
    font-weight: 700;
    font-size: 0.6rem;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-sidebar-options-btn-alert{
    color: var(--secondary-clr);
    font-size: 0.9rem;
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
    background: var(--third-clr);
    font-size: 0.8rem;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Sidebar options dropdown buttons */
.profile-sidebar-options-nested-btn-active{
    color: var(--intense-clr);
    font-weight: 500;
}
.profile-sidebar-options-nested-btn-arrow, .profile-sidebar-options-nested-btn-arrow-closed{
    position: absolute;
    right: 1em;
    font-size: 1.1rem;
}
.profile-sidebar-options-nested-btn-arrow-closed{
    transform: rotate(180deg);
}


.profile-sidebar-options-nested-sub-btn, .profile-sidebar-options-nested-sub-btn-active{
    position: relative;
    width: 100%;
    border: none;
    display: flex;
    padding: 0.5em 0.5em 0.5em 3em;
    cursor: pointer;
    color: var(--font-light);
    background: var(--main-clr);
}
.profile-sidebar-options-nested-sub-btn:hover{
    background: var(--third-clr);
}
.profile-sidebar-options-nested-sub-btn-active{
    background: var(--secondary-clr);
    color: var(--main-clr);
    font-weight: 500;
}


/* Sidebar shrinked */
.profile-sidebar-shrinked{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 6%;
    height: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid var(--border-clr);
    box-shadow: var(--material-shadow);
    padding: 1em 0;
}
.profile-sidebar-shrinked-top, .profile-sidebar-shrinked-bottom{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.profile-sidebar-shrinked-bottom{
    position: absolute;
    bottom: 0;
    left: 0;
}
.profile-sidebar-shrinked-link{
    position: relative;
    width: 100%;
    background: transparent;
    border-radius: 5px;
    border: none;
    padding: 0.5em;
    font-size: 22px;
    color: var(--font-clr);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.profile-sidebar-shrinked-link:hover{
    background: var(--main-clr);
    color: var(--secondary-clr);
}
#profile-sidebar-shrinked-link-active{
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    border-radius: 0 25px 25px 0;
    background: var(--secondary-clr);
}
#profile-sidebar-shrinked-link-label{
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--font-clr);
    padding: 0.3em;
    border-radius: 2px;
    color: var(--main-clr);
    font-size: 11px;
    z-index: 2;
}
.profile-sidebar-shrinked-close-btn{
    width: max-content;
    font-size: 18px;
    margin-bottom: 1em;
    border-radius: 5px;
    border: none;
    padding: 0.5em;
    background: var(--secondary-clr);
    transform: rotate(180deg);
    color: var(--main-clr);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    .profile-sidebar{
        display: none;
    }
}

@media only screen and (max-width: 480px){
    .profile-sidebar{
        display: none;
    }

    .profile-sidebar-options-btn, .profile-sidebar-options-btn-active{
        font-size: 1rem;
    }
    .profile-sidebar-options-btn-icon{
        font-size: 1.5rem;
    }
}
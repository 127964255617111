.waitlist-page{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 1em;
}
.waitlist-page h1{
    margin: 0;
    font-size: 2rem;
    font-weight: 500;
    color: var(--font-title-clr);
    text-align: center;
}
.waitlist-page p{
    margin: 0;
    color: var(--font-light);
}
.waitlist-page p span{
    color: var(--intense-clr);
    font-weight: 500;
}
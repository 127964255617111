/* Activity */
.profile-landing-activity{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-bottom: 2em;
}


.profile-landing-activity-websites {
    width: 100%;
    display: flex;
    gap: 1em;
    padding-bottom: 0.5em;
    overflow-x: auto;
}
.profile-landing-activity-websites::-webkit-scrollbar {
    height: 10px;
}  
.profile-landing-activity-websites::-webkit-scrollbar-track {
    background: rgb(232, 220, 220);
}
.profile-landing-activity-websites::-webkit-scrollbar-thumb {
    background: var(--secondary-clr);
}
.profile-landing-activity-websites::-webkit-scrollbar-thumb:hover {
    background: var(--intense-clr)
}
.profile-landing-activity-websites-single {
    min-width: 48%;
    display: flex;
    padding: 1em;
    border-radius: 5px;
    box-shadow: var(--material-shadow);
    background: white;
    cursor: pointer;
}
.profile-landing-activity-websites-single:hover{
    background: var(--third-clr);
}
.profile-landing-activity-websites-single-content{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 70%;
    height: 100%;
}
.profile-landing-activity-websites-single-content h1{
    display: flex;
    align-items: center;
    gap: 1em;
    font-weight: 500;
}
.profile-landing-activity-websites-single-content p{
    font-size: 0.8rem;
    color: var(--font-light);
    text-align: left;
}
.profile-landing-activity-websites-single-stats{
    display: flex;
    flex-direction: column;
    margin: 1em 0;
}
.profile-landing-activity-websites-single-content p span{
    font-weight: 600;
}
.profile-landing-activity-websites-single-content small{
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-right: 0.5em;
}
.profile-landing-activity-websites-single-content small span{
    font-size: 1.1rem;
}
.profile-landing-activity-websites-single-btn{
    width: max-content;
    display: flex;
    align-items: center;
    gap: 0.5em;
    background: transparent;
    color: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
    border-radius: 5px;
    padding: 0.5em 1em;
    cursor: pointer;
}
.profile-landing-activity-websites-single-btn:hover{
    background: var(--secondary-clr);
    color: var(--main-clr);
}

.profile-landing-activity-btn{
    display: flex;
    align-items: flex-end;
    gap: 0.5em;
}

.profile-landing-activity-desc{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.profile-landing-activity-desc p{
    font-size: 0.8rem;
    color: var(--font-light);
    text-align: left;
    max-width: 70%;
}
.profile-landing-activity-desc button{
    background: transparent;
    color: var(--secondary-clr);
    border: none;
    width: max-content;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 0.5em;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .profile-landing-activity-websites-single{
        width: 100%;
    }
    .profile-landing-activity-websites{
        flex-flow: wrap;
    }
    .profile-landing-activity-desc{
        width: 100%;
        flex-direction: column;
        gap: 1em;
    }
    .profile-landing-activity-desc p{
        width: 100%;
    }
}
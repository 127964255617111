.website-details-summary{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
}
.website-details-summary-card{
    background: var(--main-clr);
    padding: 1em 2em;
    display: flex;
    align-items: center;
    gap: 1em;
    border-radius: 5px;
}
.website-details-summary-card-icon{
    font-size: 2rem;
    color: var(--secondary-clr);
    font-weight: 300;
}

.website-details-summary-card div{
    display: flex;
    flex-direction: column;
}
.website-details-summary-card p{
    text-align: left;
    color: var(--font-light);
    font-size: 0.8rem;
}
.website-details-summary-card small{
    text-align: left;
    font-size: 1.5rem;
    color: var(--font-clr);
}


.website-details-summary-options{
    position: absolute;
    right: 1em;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.website-details-summary-options button{
    background: var(--secondary-clr);
    color: var(--main-clr);
    border: none;
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .website-details-summary-card{
        width: 50%;
    }
    .website-details-summary-options{
        display: none;
    }
}
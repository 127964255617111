.connections-check-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
}

.connections-check{
    width: 40%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2em;
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    border-radius: 15px;
    overflow: hidden;
}
.connections-check-top{
    position: relative;
    width: 100%;
    height: 120px;
    background: rgb(84,0,255);
background: linear-gradient(90deg, rgba(84,0,255,1) 13%, rgba(255,225,0,1) 100%);
}
.connections-check-icon{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.connections-check-icon::before{
    content: "";
    position: absolute;
    top: -7px;
    left: -7px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 2px solid var(--main-clr);
}

.connections-check-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 2em;
}
.connections-check-content h1{
    font-size: 1.5rem;
    font-weight: 300;
    color: var(--font-clr);
    margin: 0;
}
.connections-check-content h1 span{
    font-weight: 400;
}
.connections-check-content p{
    color: var(--font-light);
    font-size: 0.9rem;
    line-height: 1.5em;
    margin: 0;
}
.connections-check-name{
    color: var(--secondary-clr);
    font-weight: 500;
}
.connection-check-btns{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    margin-top: 2em;
}
.connection-check-btns button{
    padding: 1em 2em;
    border-radius: 25px;
    border: none;
    background: var(--secondary-clr);
    color: var(--main-clr);
    cursor: pointer;
}
.connection-check-btns button:hover{
    background: var(--intense-clr);
}
.connection-check-btns button:nth-child(1){
    background: transparent;
    color: var(--font-clr);
}
.connection-check-btns button:nth-child(1):hover{
    color: var(--intense-clr);
}
/* Pages websites */
.profile-websites{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.profile-websites-title, .profile-websites-add-title, .profile-websites-active-title, .profile-account-title{
    margin: 0;
    color: var(--font-clr);
    font-size: 1rem;
    text-align: left;
}


/* Website component and single websites */
.profile-websites-wrapper{
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin: 2em 0;
}
.profile-website{
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: var(--material-shadow);
    border-radius: 5px;
    background: white;
    overflow: hidden;
}
.profile-website-div{
    display: flex;
    flex-direction: column;
}
.profile-website-div-content, .profile-website-div-content-missing{
    position: relative;
    display: flex;
    overflow: hidden;
    gap: 0.3em;
    padding: 1em;
    border-bottom: 1px solid var(--border-clr);
}
.profile-website-div-content-missing{
    border-left: 4px solid var(--fail-clr);
}
.profile-website-div-title{
    width: 20%;
    display: flex;
    flex-direction: column;
}
.profile-website-div-title h4{
    margin: 0;
    font-size: 0.8rem;
    text-align: left;
    color: var(--font-light);
    font-weight: normal;
}
.profile-website-div-p{
    width: 80%;
    text-align: left;
    margin: 0;
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--font-clr);
    white-space: nowrap;
}
.profile-website-div-content a, .profile-website-div-content-missing a{
    width: 80%;
    text-decoration: none;
    color: var(--font-clr);
    font-weight: 500;
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-start;
}
.profile-website-div-content small, .profile-website-div-content-missing small{
    text-align: left;
    font-size: 11px;
    line-height: 0.9rem;
    color: var(--secondary-clr);
}
.profile-website-div-languages{
    width: 60%;
    display: flex;
    flex-flow: row wrap;
    gap: 0.5em;
    overflow: hidden;
}
.profile-website-div-languages > p{
    text-align: left;
    color: var(--font-clr);
    font-size: 0.8rem;
}
.profile-website-div-languages > div{
    display: flex;
    gap: 0.3em;
    height: max-content;
    font-weight: 500;
    font-size: 0.8rem;
    margin: 0;
    color: var(--font-clr);
}
.profile-website-div-options{
    position: absolute;
    right: 3em;
}
.profile-website-div-add-field-btn, .profile-website-actions-delete-btn{
    padding: 0;
    background: transparent;
    border: none;
    color: var(--secondary-clr);
    font-size: 16px;
    cursor: pointer;
    font-size: 0.9rem;
}
.profile-website-div-options-btn{
    background: transparent;
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-size: 15px;
    border: none;
    color: var(--secondary-clr);
    cursor: pointer;
}
.profile-website-div-expand{
    width: 100%;
    display: flex;
    gap: 1em;
    flex-direction: column;
    align-items: flex-start;
    padding: 1em;
    background: var(--main-clr);
}
.profile-website-div-expand-p{
    width: 100%;
    text-align: left;
    color: var(--font-light);
    font-size: 14px;
}


/* Websites add*/
.profile-websites-add{
    display: flex;
    justify-content: space-between;
    gap: 1em;
}
.profile-websites-add-text{
    width: 40%;
    text-align: left;
    font-size: 14px;
    color: var(--font-clr);
}
.profile-websites-add-btn-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5em;
}
.profile-websites-add-btn-wrapper small{
    text-align: left;
    color: var(--font-light);
}
.profile-websites-add-btn{
    width: max-content;
    background: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
    border-radius: 5px;
    color: var(--main-clr);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    padding: 0.5em 1em;
}
.profile-websites-add-btn:hover {
    background: var(--main-clr);
    color: var(--secondary-clr);
}


/* Websites add form*/
.profile-website-form-wrapper{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    background: rgba(0, 0, 0, 0.782);
    justify-content: center;
    align-items: center;
    overflow: scroll;
    z-index: 30;
}
#profile-website-form{
    position: relative;
    background: white;
    width: 45%;
    box-shadow: var(--material-shadow);
    border: 1px solid var(--border-clr);
    border-radius: var(--radius);
    padding: 4em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.profile-website-form-title{
    text-align: left;
    margin: 0;
    color: var(--font-clr);
    font-weight: 500;
    font-size: 1.2rem;
}
.profile-website-form-sub{
    text-align: left;
    color: var(--font-light);
    font-size: 0.9rem;
    margin-bottom: 1em;
}
.profile-website-form-close-btn{
    position: absolute;
    right: 1em;
    top: 1em;
    width: 2em;
    height: 2em;
    background: transparent;
    border: 1px solid var(--secondary-clr);
    color: var(--secondary-clr);
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s color 0.3s;
}
.profile-website-form-close-btn:hover{
    background: var(--secondary-clr);
    color: white;
}
.profile-website-form-first, .profile-website-form-second{
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.profile-website-form-next-btn{
    width: 100%;
    background: var(--secondary-clr);
    border: none;
    border-radius: var(--radius);
    color: white;
    padding: 1em 2em;
    cursor: pointer;
    transition: background 0.3s;
    will-change: background;
}
.profile-website-form-next-btn:hover{
    background: var(--intense-clr);
}


/* Confirmation */
.profile-websites-confirmation{
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 2em 4em;
    background: white;
    border-radius: var(--radius);
    border: 1px solid var(--border-clr);
    box-shadow: var(--material-shadow);
}
.profile-websites-confirmation div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.2em;
}
.profile-websites-confirmation div p{
    font-size: 0.9rem;
    color: var(--font-clr);
}
.profile-websites-confirmation div small{
    font-size: 0.8rem;
    color: var(--font-light);
}


/* Walkthrough */
.profile-website-walkthrough{
    width: 250px;
    background: var(--secondary-clr);
    position: fixed;
    z-index: 20;
    bottom: 0px;
    padding: 1em;
    border-radius: var(--radius);
}
.profile-website-walkthrough-close-btn{
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    cursor: pointer;
}
.profile-website-walkthrough::before{
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -15px;
    width: 20px;
    height: 20px;
    background: var(--secondary-clr);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
}
.profile-website-walkthrough h5{
    color: var(--main-clr);
    font-size: 0.8rem;
    margin: 0;
    text-align: center;
}
.profile-website-walkthrough p{
    color: var(--main-clr);
    font-size: 0.7rem;
    margin: 0;
    text-align: center;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .profile-website-div-content small{
        display: none;
    }
    .profile-website-div-options{
        /* position: relative; */
        right: 1em;
    }
    .profile-website-div-options-btn-icon{
        display: none;
    }


    .profile-websites-add{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .profile-websites-add-text{
        width: 100%;
    }
    .profile-websites-add-btn-wrapper{
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2em;
    }

    #profile-website-form{
        width: 100%;
        height: 100vh;
        border-radius: 0;
    }
}
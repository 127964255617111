.website-details-danger-zone{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    gap: 1em;
}
.website-details-danger-zone h1{
    color: var(--font-clr);
}


.website-details-danger-zone-options{
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.website-details-danger-zone-options > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--main-clr);
    border: 1px solid var(--border-clr);
    border-radius: 5px;
    padding: 1em;
}
.website-details-danger-zone-options > div > div{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 70%;
}
.website-details-danger-zone-options > div > div h5{
    text-align: left;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
}
.website-details-danger-zone-options > div p{
    text-align: left;
    font-size: 0.9rem;
    color: var(--font-light);
}
.website-details-danger-zone-options-edit-btn, .website-details-danger-zone-options-delete-btn{
    color: var(--fail-clr);
    border-color: var(--fail-clr);
    padding: 0.5em 1em;
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
}
.website-details-danger-zone-options-edit-btn:hover, .website-details-danger-zone-options-delete-btn:hover{
    background: var(--fail-clr);
    color: var(--main-clr);
}


.website-details-danger-zone-alert{
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.737);
    display: flex;
    align-items: center;
    justify-content: center;
}
.website-details-danger-zone-alert-form{
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    border: 1px solid var(--border-clr);
    padding: 5em;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    gap: 0.5em;
}
.website-details-danger-zone-alert-input, .website-details-danger-zone-alert-input-error{
    padding: 1em;
    border-radius: 5px;
    border: 1px solid var(--border-clr);
    margin-top: 1em;
}
.website-details-danger-zone-alert-input-error{
    border: 2px solid var(--fail-clr);
}
.website-details-danger-zone-alert-form span{
    text-align: left;
    margin-bottom: 1em;
    color: var(--fail-clr);
    font-size: 0.8rem;
}
.website-details-danger-zone-alert-form p{
    text-align: left;
}
.website-details-danger-zone-alert-form small{
    text-align: left;
    color: var(--font-light);
    font-size: 0.8rem;
}
.website-details-danger-zone-alert-form > div{
    display: flex;
    width: 100%;
    gap: 1em;
}
.website-details-danger-zone-alert-form > div button:nth-child(1){
    width: 50%;
    border: 1px solid var(--font-clr);
    color: var(--font-clr);
    padding: 1em;
    border-radius: 5px;
    cursor: pointer;
}
.website-details-danger-zone-alert-form > div button:nth-child(2){
    width: 50%;
    background: var(--fail-clr);
    color: var(--main-clr);
    padding: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.website-details-danger-zone-alert-form > div > button:hover{
    background: red;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .website-details-danger-zone-options > div{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1em;
    }
    .website-details-danger-zone-options > div > div{
        width: 100%;
    }

    .website-details-danger-zone-alert-form{
        width: 90%;
        padding: 1em;
    }
}
.register-plans{
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: var(--main-light);
}


.register-plans-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 4em 8em;
    gap: 4em;
}
.register-plans-content > div:nth-child(1){
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 40%;
    height: 100vh;
    overflow: hidden;
}
.register-plans-content > div:nth-child(1) img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.register-plans-warning{
    background: var(--secondary-clr);
    padding: 1em;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 1em;
}
.register-plans-warning > div{
    color: var(--main-clr);
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.register-plans-warning p{
    text-align: left;
    color: var(--main-clr);
    font-size: 0.9rem;
}

/* Checkout info */
.register-plans-info{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.register-plans-info-content{
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.register-plans-info-content h5{
    font-size: 1.5rem;
    line-height: 1.1em;
    font-weight: 400;
    text-align: left;
    color: var(--font-title-clr);
    margin: 0;
}
.register-plans-info-content p{
    text-align: left;
    color: var(--font-landing-clr);
    margin: 0 0 0.5em 0;
    font-size: 0.9rem;
    line-height: 1.5em;
}
.register-plans-info-content > div{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.register-plans-info-content li{
    text-align: left;
    font-size: 0.9rem;
    color: var(--font-light);
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
#checkout-plans-icon{
    color: var(--success-clr);
}


.register-plans-options{
    width: 100%;
    display: flex;
    gap: 2em;
}
.register-plans-option{
    width: 100%;
    box-shadow: var(--material-shadow);
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding: 2em;
    border-radius: 5px;
}
.register-plans-option:hover{
    background: var(--main-light);
}


.register-plans-option > div:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.register-plans-option h5{
    text-align: left;
    color: var(--font-title-clr);
    margin: 0;
    font-size: 1.2rem;
    font-weight: 400;
}
.register-plans-option h5 span{
    background: var(--third-clr);
    font-size: 0.8rem;
    color: var(--font-landing-clr);
    border-radius: 15px;
    padding: 0.3em 0.6em;
}
.register-plans-option p{
    text-align: left;
    color: var(--font-landing-clr);
    width: 100%;
    margin: 0;
}
.register-plans-options small{
    text-align: left;
    margin: 0;
    color: var(--font-light);
}

.register-plans-option-links{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
}
.register-plans-options-link{ 
    background: var(--secondary-clr);
    color: var(--main-clr);
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
}
.register-plans-options-link:hover{
    background: var(--intense-clr);
}

/* MEDIA QUERIES */
@media only screen and (max-width: 480px){
    .register-plans{
        padding: 8em 1em;
    }
    .register-plans-content{
        flex-direction: column;
    }
    .register-plans-content > div:nth-child(1){
        display: none;
    }
    .register-plans-options, .checkout-info{
        width: 100%;
        padding: 1em;
    }
    .register-plans-option{
        flex-direction: column;
        gap: 1em;
    }
    .register-plans-options-link{
        width: 100%;
    }
}
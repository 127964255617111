:root{
  --main-clr: #fbfafe;
  --main-light: #f8f7ff;
  --main-light-plus: #d2cbff;
  --font-title-clr: #1f0b46;
  --font-landing-clr: #43365d;
  --secondary-clr: #5400ff;
  --secondary-light: #7633FF;
  --third-clr: #ffe100;
  --third-clr-intense: #89cd00;
  --third-clr-light:#ffc85b;
  --intense-clr: #35009e;
  --font-clr: #151515;
  --font-light: #434343;
  --border-clr: #dfdfdf;
  --success-clr: #49ff46;
  --fail-clr: #ff3333;
  --warning-clr: rgb(255, 255, 84);
  --radius: 10px;
  --blur-clr: rgba(132, 132, 132, 0.8);
  --material-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  --material-shadow-intense: 5px 5px 3px #ebebeb, -5px -5px 3px #ffffff;
  --material-shadow-double: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

*{
  box-sizing: border-box;
}

html{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
body{
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  font-family: 'Noto Sans', sans-serif;
  overflow-x: hidden;
}
body p{
  margin: 0;
}
body textarea{
  font-family: 'Noto Sans', sans-serif
}


.app-wrapper {
  text-align: center;
}


.walkthrough-tooltip{
  color: var(--secondary-clr);
}


/* Buttons */
.primary-btn{
  background: var(--secondary-clr);
  color: var(--main-clr);
  border: none;
  border-radius: 5px;
  padding: 0.7em 1.4em;
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;
}
.primary-btn:hover{
  background: var(--intense-clr);
}
.primary-btn-disabled{
  border: 1px solid var(--border-clr);
  padding: 0.5em 1em;
  color: var(--font-light);
  border-radius: 5px;
  cursor: not-allowed;
}
.close-btn{
  position: absolute;
  right: -13px;
  top: -13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: var(--main-clr);
  border: 1px solid var(--secondary-clr);
  color: var(--secondary-clr);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.modal-wrapper{
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}
/* section 3 */
.partners{
    position: relative;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.partners-top{
    background: var(--main-light);
    border-radius: 15px;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 4em;
    transform: translateY(5em);
}
.partners-top p{
    color: var(--font-landing-clr);
    font-size: 1.3rem;
    margin: 0;
}

.partners-top-reviews{
    display: flex;
    align-items: center;
    gap: 1em;
}
.partners-top-reviews > div{
    display: flex;
    align-items: center;
}
.partners-top-reviews > div > div{
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background: grey;
    overflow: hidden;
}
.partners-top-reviews > div > div img{
    width: 100%; 
    height: 100%;
    object-fit: contain;
}
.partners-top-reviews > div > div:nth-child(2){
    transform: translateX(-10px);
}
.partners-top-reviews > div > div:nth-child(3){
    transform: translateX(-20px);
}
.partners-top-reviews small{
    font-size: 0.8rem;
    color: var(--font-landing-clr);
    transform: translateX(-30px);
}


.partners-bottom{
    width: 100%;
    height: 50%;
    background: var(--secondary-clr);
    /* clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 8em 8em 4em 8em;
}
.partners-bottom h4{
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--main-clr);
    margin: 0;
    font-family: 'Work Sans', sans-serif;
}
.partners-bottom p{
    text-align: center;
    color: var(--main-light);
    font-size: 0.9rem;
    margin: 0;
}
.partners-bottom-join{
    width: 50%;
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-top: 2em;
}
.partners-bottom-join input{
    width: 100%;
    height: 3em;
    padding: 1em;
    border-radius: 5px;
    border: 1px solid grey;
}
.partners-bottom button{
    width: max-content;
    white-space: nowrap;
    height: 3em;
    padding: 0.5em 1em;
    background: var(--third-clr);
    color: var(--intense-clr);
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.partners-bottom button:hover{
    background: var(--intense-clr);
}


@media only screen and (max-width: 980px){

}
@media only screen and (max-width: 480px){
    .partners-top{
        padding: 2em;
    }
    .partners-top p{
        width: 100%;
    }
    .partners-bottom{
        padding: 8em 1em 1em 1em;
    }
    .partners-bottom-join{
        width: 100%;
    }
}
.guide{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4em;
    padding: 8em;
}

.guide-top{
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.guide-top h1{
    font-size: 2.5rem;
    text-align: left;
    margin: 0;
    color: var(--font-title-clr);
    font-family: 'Work Sans', sans-serif;
}
.guide-top p{
    margin: 0;
    text-align: left;
    line-height: 1.5em;
    width: 80%;
    color: var(--font-landing-clr);
}

.guide-content{
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.guide-content-nav{
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: 2em;
}
.guide-content-nav-btn, .guide-content-nav-btn-active{
    position: relative;
    background: transparent;
    color: var(--font-light);
    border: none;
    padding: 0;
    border-radius: 15px;
    font-size: 1rem;
    cursor: pointer;
}
.guide-content-nav-btn-active::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background: var(--intense-clr);
}

.guide-single{
    width: 100%;
    display: flex;
    gap: 2em;
}

.guide-single-text{
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.guide-single-text > div{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding-left: 1em;
}
.guide-single-text > div::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background: var(--secondary-clr);
}
.guide-single-text h5{
    text-align: left;
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--font-title-clr);
}
.guide-single-text h5 span{
    font-size: 0.8rem;
    color: var(--secondary-clr);
    margin-right: 1em;
}
.guide-single-text > div p{
    text-align: left;
    color: var(--font-landing-clr);
    font-size: 0.9rem;
}
.guide-single-link{
    text-decoration: none;
    width: max-content;
    background: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
    color: var(--main-clr);
    border-radius: 5px;
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    gap: 1em;
}
.guide-single-link:hover{
    background: var(--secondary-clr);
    color: var(--main-clr);
}

.guide-single-img{
    width: 65%;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: var(--material-shadow-intense);
}
.guide-single-img-top{
    width: 100%;
    height: 100px;
    background: var(--secondary-clr);
}
.guide-single-img img{
    width: 95%;
    height: 100%;
    border-radius: 5px;
    transform: translateY(-50px);
}


@media only screen and (max-width: 980px){
    .guide{
        padding: 4em;
    }
    .guide-single{
        flex-direction: column-reverse;
    }
    .guide-single-text, .guide-single-img{
        width: 100%;
    }
}
@media only screen and (max-width: 480px){
    .guide{
        padding: 2em;
    }
    .guide p{
        width: 100%;
    }
    .guide-single{
        flex-direction: column;
    }
    .guide-single-img{
        display: none;
    }
    .guide-single-text{
        width: 100%;
    }
}
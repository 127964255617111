.notifications{
    position: absolute;
    right: 5px;
    top: 30px;
    width: 350px;
    max-height: 50vh;
    border-radius: var(--radius);
    border: 1px solid var(--border-clr);
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    z-index: 10;
}
.notifications::-webkit-scrollbar {
    width: 8px;
}  
.notifications::-webkit-scrollbar-track {
    background: var(--main-clr);
    border-radius: 0 var(--radius) var(--radius) 0;
}  
.notifications::-webkit-scrollbar-thumb {
    background: var(--font-light);
    border-radius: 0 var(--radius) var(--radius) 0;
}  
.notifications::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-clr);
}


.notifications-title{
    color: var(--font-clr);
    font-weight: 400;
    font-size: 1rem;
    margin: 0;
    text-align: left;
    padding: 0.5em 1em;
    user-select: none;
}
.notifications-view-btn{
    width: 100%;
    background: transparent;
    padding: 1em;
    border: none;
    color: var(--secondary-clr);
    background: var(--main-clr);
    cursor: pointer;
    text-align: left;
}
.notifications-empty{
    padding: 1em;
}
.notifications-empty p{
    color: var(--font-clr);
    text-align: left;
    font-size: 0.9rem;
}


.notification-single{
    position: relative;
    padding: 1em;
    border-bottom: 1px solid var(--border-clr);
    display: flex;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
}
.notification-single:hover{
    background: var(--main-light);
}
.notification-single-dot{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--secondary-clr);
    transform: translateY(5px);
    align-self: flex-start;
}
.notification-single-img{
    width: 30px;
    height: 30px;
    border: 1px solid var(--secondary-clr);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary-clr);
    font-size: 1.2rem;
}
.notification-single-content{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 0.3em;
}
.notification-single-content h1, .notification-single-content p, .notification-single-content small{
    text-align: left;
    font-weight: 500;
    color: var(--font-clr);
    margin: 0;
}
.notification-single-content p{
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 1.3em;
}
.notification-single-content small {
    font-size: 0.7rem;
    color: var(--font-light);
}
.notification-single-delete-btn{
    position: absolute;
    right: 0em;
    top: 1em;
    background: transparent;
    border: none;
    color: var(--font-clr);
    cursor: pointer;
    transition: transform 0.3s;
}
.notification-single-delete-btn:hover{
    transform: scale(1.1);
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){

}

@media only screen and (max-width: 480px){
    .notifications{
        right: -80px;
    }
}
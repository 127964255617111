.profile-conversations{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}


/* Nav */
.profile-conversations-nav{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0 1em;
    border-bottom: 1px solid var(--border-clr);
}
.profile-conversations-nav > p{
    color: var(--font-clr);
    font-weight: 500;
    font-size: 14px;
}
.profile-conversations-nav > div{
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-right: 1em;
}
.profile-conversations-nav > div > div{
    width: max-content;
}
.profile-conversation-nav-btn, .profile-conversation-nav-btn-active{
    position: relative;
    color: var(--font-light);
    background: transparent;
    font-size: 14px;
    padding: 0.8em;
    cursor: pointer;
    border: none;
}
.profile-conversation-nav-btn::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: var(--main-light);
    border-radius: 25px 25px 0 0;
}
.profile-conversation-nav-btn-active::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: var(--secondary-clr);
    border-radius: 25px 25px 0 0;
}
.profile-conversation-nav-btn:hover, .profile-conversation-nav-btn-active:hover{
    color: var(--font-clr);
}
.profile-conversation-nav-btn span, .profile-conversation-nav-btn-active span{
    position: absolute;
    right: -1em;
    top: 50%;
    transform: translateY(-50%);
    color: var(--main-clr);
    font-size: 10px;
    width: 15px;
    height: 15px;
    background: var(--fail-clr);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-conversations-nav-options{
    position: absolute;
    right: 0.5em;
}
.profile-conversations-nav-options-btns{
    background: var(--secondary-clr);
    border: none;
    border-radius: 5px;
    padding: 0.5em;
    color: var(--main-clr);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}


.profile-conversations-bottom{
    width: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
}


.conversation-chat-btn{
    position: fixed;
    z-index: 11;
    bottom: 1em;
    right: 3em;
    width: 50px;
    height: 50px;
    background: var(--secondary-clr);
    color: var(--main-clr);
    box-shadow: var(--material-shadow);
    font-size: 24px;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}
.conversation-chat-btn:hover{
    transform: scale(1.1);
}
.conversation-chat-btn::after{
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    width: 54px;
    height: 54px;
    border: 3px solid var(--secondary-clr);
    box-shadow: var(--material-shadow);
    border-radius: 50%;
}
.conversation-chat-btn-notification{
    position: absolute;
    right: -0.5em;
    top: -0.5em;
    background: var(--fail-clr);
    color: var(--main-clr);
    width: 1.3em;
    height: 1.3em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    z-index: 999;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .conversation-chat-btn{
        right: 1em;
        bottom: 1em;
    }

    .conversation-messages, .conversation-messages-open{
        width: 90%;
        right: 1em;
    }
    .profile-conversations-bottom{
        flex-direction: column;
        height: max-content;
        gap: 0;
    }
}

.register-incomplete{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 8em;
}
.register-incomplete h1{
    color: var(--font-title-clr);
    text-align: left;
    margin: 0;
}
.register-incomplete p{
    color: var(--font-landing-clr);
    text-align: left;
    margin: 0;
    line-height: 1.5em;
}
.register-incomplete span{
    font-weight: 700;
}
.register-incomplete-link{
    width: max-content;
    background: var(--secondary-clr);
    border: none;
    border-radius: var(--radius);
    color: var(--main-clr);
    padding: 0.5em 1em;
    cursor: pointer;
    margin-top: 1em;
}
.register-incomplete-link:hover{
    background: var(--intense-clr);
}
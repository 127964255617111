/* Overview */
.billing-plan-overview{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.billing-plan-overview h5, .billing-details h5{
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    margin: 0;
    padding-bottom: 1em;
}



.billing-plan-overview-content{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    background: var(--main-clr);
    padding: 2em;
    border-radius: 5px;
}
.billing-plan-overview-content-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.billing-plan-overview-btns{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
}
.billing-plan-overview-top-btns-settings{
    position: relative;
}
.billing-plan-overview-top-btns-settings button, .billing-plan-overview-top-link{
    padding: 0.5em;
    border: none;
    background: var(--secondary-clr);
    color: var(--main-clr);
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}
.billing-plan-overview-top-link{
    font-size: 0.9rem;
    text-decoration: none;
    padding: 0.5em 1em;
}
.billing-plan-overview-top-btns-settings button:hover, .billing-plan-overview-top-link:hover{
    background: var(--intense-clr);
}
.billing-plan-overview-top-btns-settings > div{
    box-shadow: var(--material-shadow);
    width: 100px;
    position: absolute;
    top: 40px;
    right: 5px;
}
.billing-plan-overview-content-info{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.billing-plan-overview-content-info > div{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.billing-plan-overview-content-info > div p{
    text-align: left;
    color: var(--font-clr);
}
.billing-plan-overview-content-info > div small{
    text-align: left;
    color: var(--font-light);
    font-size: 0.8rem;
}
.billing-plan-overview-content-info > div span{
    text-align: left;
    color: var(--font-light);
    font-size: 0.8rem;
}
.billing-plan-overview-content-info small{
    text-align: left;
    color: var(--font-clr);
    font-size: 0.9rem;

}
.billing-plan-overview-content li{
    color: var(--secondary-clr);
    text-align: left;
    list-style-type: none;
    font-size: 0.8rem;
}


.billing-details{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.billing-details-content{
    display: flex;
    flex-direction: column;
    gap: 2em;
    background: var(--main-clr);
    padding: 2em;
    border-radius: 5px;
}
.billing-details-content button{
    width: max-content;
    background: transparent;
    border: none;
    font-size: 1rem;
    color: var(--secondary-clr);
    padding: 0;
    cursor: pointer;
}
.billing-details-content button:hover{
    color: var(--intense-clr);
}
.billing-details-content > small{
    text-align: left;
    color: var(--font-light);
}

.billing-details-content-single{
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 1em;
    border-radius: 5px;
}
.billing-details-content-single-icon{
    width: 30px;
    overflow: hidden;
    transform: translateY(5px);
}
.billing-details-content-single-icon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.billing-details-content-single > div{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.billing-details-content-single > div p{
    text-align: left;
    color: var(--font-clr);
    font-weight: 500;
    font-size: 0.9rem;
    display: flex;
    gap: 0.5em;
    margin: 0;
}
.billing-details-content-single > div small{
    text-align: left;
    color: var(--font-clr);
    font-size: 0.9rem;
    margin: 0;
}
#billing-details-content-single-icon{
    color: var(--font-light);
    transform: translateY(3px);
}
#billing-details-content-single-remove-btn{
    background: var(--secondary-clr);
    color: var(--main-clr);
    padding: 0.5em 1em;
    border-radius: 5px;
    border: none;
    font-size: 0.9rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
#billing-details-content-single-remove-btn:hover{
    background: var(--intense-clr);
}
#billing-details-content-single-remove-btn:disabled{
    background: rgb(173, 168, 183);
    user-select: none;
    cursor:not-allowed;
}
/* #billing-details-content-single-remove-btn:hover::after{
    opacity: 1;
} */
/* #billing-details-content-single-remove-btn::after{
    content: "Remove this method";
    position: absolute;
    top: -25px;
    right: 0;
    background: var(--secondary-clr);
    color: var(--main-clr);
    font-size: 0.8rem;
    white-space: nowrap;
    padding: 0.3em;
    border-radius: 5px;
    user-select: none;
    opacity: 0;
    transition: opacity 0.3s;
} */
.billing-details-content li{
    list-style-type: none;
    text-align: left;
    color: var(--font-clr);
}


/* Payment method add */
.billing-overview-payment-add{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 10;
    background: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    padding-top: 4em;
}
.billing-overview-payment-add-form{
    position: relative;
    width: 40%;
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    padding: 2em;
    border-radius: 5px;
}
.billing-overview-payment-add-close-btn{
    position: absolute;
    right: 1em;
    top: 1em;
}
#billing-add-payment-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-top: 2em;
}
#billing-add-payment-form small{
    color: var(--fail-clr);
    text-align: left;
}
#billing-add-payment-form-btn:disabled{
    background: rgb(173, 168, 183);
    user-select: none;
    cursor:not-allowed;
}
.company{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.company-hero{
    width: 100%;
    display: flex;
}
.company-hero-content{
    padding: 8em 8em 4em 8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.company-hero-content h1{
    font-size: 4rem;
    color: var(--font-title-clr);
    line-height: 0.9em;
    text-align: center;
}
.company-hero-content p{
    width: 60%;
    color: var(--font-landing-clr);
}


.company-mission{
    width: 100%;
    padding: 4em 8em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.company-mission > div{
    width: 100%;
    display: flex;
    gap: 2em;
    box-shadow: var(--material-shadow);
    padding: 2em;
    border-radius: 15px;
}
.company-mission > div > div:nth-child(1){
    height: 200px;
    width: 50%;
    background: var(--secondary-clr);
}
.company-mission > div > div:nth-child(2){
    width: 50%;
}
.company-mission > div > div:nth-child(2) h2{
    text-align: left;
    color: var(--font-title-clr);
}
.company-mission > div > div:nth-child(2) p{
    text-align: left;
    font-size: 0.9rem;
    line-height: 1.5em;
    color: var(--font-landing-clr);
}

.company-content{
    width: 100%;
    padding: 4em 8em;
    display: flex;
    flex-direction: column;
    gap: 4em;
    justify-content: center;
    align-items: center;
}
.company-content h2{
    color: var(--font-title-clr);
    font-size: 2rem;
    margin: 0;
}
.company-content > div{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: 1em;
}
.company-content > div > div{
    width: 48%;
    box-shadow: var(--material-shadow);
    background: var(--main-light);
    border-radius: 15px;
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.company-content > div > div h5{
    margin: 0;
    text-align: left;
    color: var(--font-title-clr);
    font-size: 1rem;
}
.company-content > div > div p{
    margin: 0;
    text-align: left;
    color: var(--font-landing-clr);
}

.company-join{
    width: 100%;
    padding: 4em 8em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.company-join h5{
    text-align: left;
    margin: 0;
    font-size: 1.5rem;
    color: var(--font-title-clr);
}
.company-join p{
    margin: 0;
    text-align: left;
    color: var(--font-landing-clr);
    font-size: 0.9rem;
    line-height: 1.5em;
}

@media only screen and (max-width: 480px){
    .company-hero-content{
        padding: 8em 1em;
    }
    .company-hero-content p{
        width: 100%;
    }

    .company-mission, .company-content, .company-join{
        padding: 2em 1em;
    }
    .company-mission > div{
        flex-direction: column-reverse;
    }
    .company-mission > div > div:nth-child(1), .company-mission > div > div:nth-child(2){
        width: 100%;
    }

    
    .company-content > div > div{
        width: 100%;
    }

    
}
.admin-page{
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 10vh;
    padding-bottom: 2em;
}
.admin-page-content{
    position: relative;
    width: 83%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 1em;
}


/* Admin panel */
.admin-panel{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.admin-panel-h1, .admin-panel-all-h1{
    font-size: 1rem;
    text-align: left;
    font-weight: 400;
}
.admin-panel-sub{
    color: var(--font-light);
    text-align: left;
    width: 100%;
    line-height: 1.5em;
}
.admin-panel-all-header{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
}



.admin-panel-all-header div{
    display: flex;
    align-items: center;
    gap: 0.5em;
    border: 1px solid var(--border-clr);
    border-radius: var(--radius);
    padding: 0.2em 0.4em;
}
.admin-panel-all-header div input{
    background: transparent;
    border: none;
    outline: none;
}


.admin-panel-all-users-sort{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
}
.admin-panel-all-users-sort-btn{
    background: transparent;
    border: 1px solid var(--secondary-clr);
    color: var(--secondary-clr);
    border-radius: var(--radius);
    padding: 0.5em 1em;
    cursor: pointer;
    transition: all 0.3s;
}
.admin-panel-all-users-sort-btn-active{
    background: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
    color: white;
    border-radius: var(--radius);
    padding: 0.5em 1em;
    cursor: pointer;
    transition: all 0.3s;
}
.admin-panel-all-users-sort-select-btn{
    width: max-content;
    position: absolute;
    right: 0;
    box-shadow: var(--material-shadow);
    border: 1px solid var(--border-clr);
    border-radius: var(--radius);
}

/* Components style */
.admin-h1{
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    color: var(--font-clr);
}
.admin-sub{
    color: var(--font-light);
    text-align: left;
    margin: 0;
    font-size: 0.9rem;
}
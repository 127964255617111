.onboarding{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}


.onboarding-bar{
    width: 20%;
    height: 95%;
    background: white;
    box-shadow: var(--material-shadow);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    padding: 2em;
}
.onboarding-bar-div, .onboarding-bar-div-active{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
    color: var(--font-clr);
}
.onboarding-bar-div-active{
    color: var(--success-clr);
}

.onboarding-content{
    width: 50%;
    height: 95%;
    background: white;
    border-radius: 5px;
    box-shadow: var(--material-shadow);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.onboarding-content-top{
    position: relative;
    padding: 2em;
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.onboarding-content-top img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.onboarding-close-btn{
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: 2em;
    right: 2em;
    background: var(--main-clr);
    border: 1px solid var(--border-clr);
    box-shadow: var(--material-shadow);
    cursor: pointer;
    transition: all 0.3s;
}
.onboarding-close-btn:hover{
    transform: scale(1.1);
}


.onboarding-intro{
    height: 50%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0 2em 2em 2em;
}
.onboarding-intro-text{
    height: 70%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    overflow-y: auto;
}
.onboarding-intro h1{
    text-align: left;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--font-clr);
}
.onboarding-intro p{
    text-align: left;
    margin: 0;
    color: var(--font-clr);
    font-size: 0.9rem;
    line-height: 1.5em;
}
.onboarding-intro-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.onboarding-intro-btns > div{
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.onboarding-intro-span, .onboarding-intro-span-active{
    width: 6px;
    height: 6px;
    background: var(--font-light);
    border-radius: 50%;
}
.onboarding-intro-span-active{
    width: 16px;
    border-radius: 2px;
    background: var(--secondary-clr);
}

.onboarding-intro-btns-start{
    display: flex;
    align-items: center;
    gap: 1em;
}
.onboarding-intro-skip-btn{
    background: transparent;
    border: none;
    cursor: pointer;
}
.onboarding-intro-btn{
    border-radius: 5px;
    background: var(--secondary-clr);
    border: none;
    color: var(--main-clr);
    padding: 1em 2em;
    cursor: pointer;
}
.onboarding-intro-btn:hover{
    background: var(--intense-clr);
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .onboarding-content{
        width: 100%;
        height: 100vh;
    }
}
.settings-sidebar{
    position: relative;
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 1.5em;
    background: var(--main-clr);
}
.settings-sidebar-add-btn{
    background: var(--secondary-clr);
    border: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: var(--main-clr);
    margin-top: 2em;
    cursor: pointer;
}


.settings-sidebar-option, .settings-sidebar-option-active, .settings-sidebar-website-option, .settings-sidebar-website-option-active{
    background: transparent;
    border: none;
    width: 100%;
    font-size: 0.9rem;
    padding: 0.5em 1em;
    text-align: left;
    text-transform: capitalize;
    border-radius: 5px;
    cursor: pointer;
}
.settings-sidebar-option-active, .settings-sidebar-website-option-active{
    border-left: 2px solid var(--secondary-clr);
    border-radius: 0 5px 5px 0;
}
.settings-sidebar-option:hover, .settings-sidebar-option-active:hover, .settings-sidebar-website-option:hover, .settings-sidebar-website-option-active:hover{
    background: var(--main-light-plus);
}


.settings-sidebar-websites-wrapper{
    padding-left: 1em;
}
.settings-sidebar-website-option, .settings-sidebar-website-option-active{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    text-transform: none;
}
.settings-sidebar-website-option small, .settings-sidebar-website-option-active small{
    color: var(--font-light);
    font-size: 0.9rem;
    margin: 0;
}
.settings-sidebar-website-option span, .settings-sidebar-website-option-active span{
    position: absolute;
    color: red;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
}
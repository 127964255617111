.logs{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1.5em;
    background: var(--main-light);
    padding-bottom: 4em;
    border-left: 1px solid var(--border-clr);
}
.logs h1{
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    color: var(--font-clr);
    text-align: left;
}


.logs-wrapper{
    display: flex;
    flex-direction: column;
}

.log-single, .logs-titles{
    width: 100%;
    display: grid;
    grid-template-columns: 15% 70% 15%;
    border-bottom: 1px solid var(--border-clr);
    border-radius: 0;
    background: var(--main-light);
    transition: background 0.2s;
    will-change: background;
}
.log-single > div, .logs-titles > div{
    padding: 0.8em 0;
    text-align: left;
    display: flex;
    align-items: center;
    color: var(--font-light);
    overflow: hidden;
}
.log-single > div p, .logs-titles > div p{
    text-align: left;
    white-space: nowrap;
    font-size: 0.8rem;
}
.log-single > div span{
    color: var(--secondary-clr);
}
.logs-titles > div p{
    font-weight: 600;
}
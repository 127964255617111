/* Mobile links menu */
.nav-menu-mobile{
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--main-clr);
    display: flex;
    flex-direction: column;
}
.nav-menu-mobile-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2em 2em 2em 1em;
}
.nav-menu-mobile-close-btn{
    background: transparent;
    border: none;
    font-size: 1.2rem;
}


.nav-menu-mobile-links{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding: 1em;
}
.nav-menu-mobile-link{
    width: 100%;
    background: transparent;
    border: none;
    display: flex;
    align-items: flex-start;
    padding: 0;
    color: var(--font-clr);
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    gap: 1em;
}
.nav-menu-mobile-link-signup{
    text-decoration: none;
    width: 100%;
    background: var(--third-clr);
    padding: 0.5em;
}


.nav-mobile-copyright{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1em;
    width: 100%;
    background: var(--main-light-plus);
    border-radius: 5px;
}
.outlinks{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em 2em;
    gap: 1em;
}
.outlinks-top{
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.outlinks-title{
    font-size: 1.2rem;
    text-align: left;
    margin: 0;
}
.outlinks-sub{
    font-size: 14px;
    text-align: left;
    color: var(--font-clr);
    margin: 0;
    margin-bottom: 1em;
}
.outlinks-top div{
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.outlinks-top div button{
    background: var(--secondary-clr);
    border: none;
    color: var(--main-clr);
    padding: 0.5em 1em;
    border-radius: 5px;
}


/* Outlinks category bar */
.outlinks-category-bar{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2em;
}
.outlinks-category-bar-btns{
    display: flex;
    align-items: center;
}
.outlinks-category-bar-option, .outlinks-category-bar-option-active{
    position: relative;
    background: transparent;
    border: none;
    border-radius: 25px;
    color: var(--font-clr);
    padding: 0;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em 1em;
    cursor: pointer;
}
.outlinks-category-bar-option-active{
    background: var(--secondary-clr);
    color: var(--main-clr);
}

/* Nav */
.outlinks-nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.outlinks-nav-search{
    border: 1px solid var(--border-clr);
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.outlinks-nav-search input{
    background: transparent;
    border: none;
    outline: none;
    padding: 0.5em 1em;
}
.outlinks-nav-search-btn{
    background: var(--secondary-clr);
    color: var(--main-clr);
    border: none;
    height: 100%;
    padding: 0.5em 1em;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    transition: background 0.3s;
}
.outlinks-nav-search-btn:hover{
    background: var(--intense-clr);
}

.outlinks-nav select{
    padding: 0.7em 1.4em;
    border: 1px solid var(--border-clr);
    border-radius: var(--radius);
    cursor: pointer;
}
.outlinks-nav option{
    cursor: pointer;
    font-size: 0.9rem;
}

/* Skeleton */
.outlinks-skeleton{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em;
    gap: 1em;
}
.outlinks-skeleton div{
    background: var(--main-light);
    border-radius: var(--radius);
}
.outlinks-skeleton div:nth-child(1){
    width: 20%;
    height: 20px;
}
.outlinks-skeleton div:nth-child(2){
    width: 30%;
    height: 20px;
}
.outlinks-skeleton div:nth-child(3){
    margin-top: 1em;
    width: 100%;
    height: 40px;
}
.outlinks-skeleton div:nth-child(4){
    width: 100%;
    height: 200px;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .outlinks{
        padding: 1em;
    }
    .outlinks-stats{
        flex-direction: column;
        gap: 1em;
        overflow: hidden;
    }
    .outlinks-stats-single{
        width: 100%;
    }

    .outlinks-category-bar-btns-icon{
        display: none;
    }

    .outlinks-table-title, .outlinks-single{
        grid-template-columns: 30% 30% 20% 20%;
    }
    .outlinks-table-title div{
        font-size: 0.7rem;
        overflow: hidden;
    }
    .outlinks-single > div:nth-child(3), .outlinks-table-title div:nth-child(3){
        display: none;
    }
    .outlinks-single > div:nth-child(4), .outlinks-table-title div:nth-child(4){
        display: none;
    }
    .outlinks-single > div:nth-child(5), .outlinks-table-title div:nth-child(5){
        display: none;
    }

    .outlinks-single-actions{
        overflow: hidden;
        padding: 0;
    }
    .backlinks-single-actions-confirm-btn{
        font-size: 0.8rem;
        width: 100%;
        height: 100%;
        padding: 0.5em;
    }
}
.error-message {
    width: 100%;
}
.error-p{
    color: rgb(255, 45, 45);
    margin: 0;
    font-size: 0.8rem;
    text-align: left;
}


.error-popup{
    width: 200px;
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    background: var(--fail-clr);
    color: white;
    box-shadow: var(--material-shadow);
    border-radius: var(--radius);
    padding: 1em;
}
.error-popup p{
    text-align: left;
    font-size: 0.8rem;
}


.success-message{
    background: var(--main-clr);
    position: fixed;
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%);
    border: 1px solid var(--border-clr);
    border-radius: var(--radius);
    box-shadow: var(--material-shadow);
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
}
.success-message p{
    margin: 0;
    color: var(--font-clr);
}
.success-message small{
    margin: 0;
    color: var(--font-light);
}


.token-expired-message{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.745);
    color: var(--font-clr);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;
}
.token-expired-message div{
    position: relative;
    width: 30%;
    padding: 2em;
    border: 1px solid var(--border-clr);
    border-radius: var(--radius);
    box-shadow: var(--material-shadow);
    background: var(--main-clr);
}
.token-expired-message button{
    position: absolute;
    width: 2em;
    height: 2em;
    right: 0.5em;
    top: 0.5em;
    border: 1px solid var(--border-clr);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}
.token-expired-message button:hover{
    background: var(--secondary-clr);
    color: var(--main-clr);
}

.toast{
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    display: flex;
    align-items: center;
    gap: 1em;
    background: var(--main-clr);
    border: 1px solid rgb(231, 231, 231);
    border-radius: var(--radius);
    padding: 2em 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 999;
}
.toast-line{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background: var(--success-clr);
}
.toast-icon{
    width: 10%;
    height: 100%;
    font-size: 25px;
    color: var(--success-clr);
    display: flex;
    align-items: center;
    justify-content: center;
}
.toast-text{
    position: relative;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 0.2em;
}
.toast-text p:nth-child(1){
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    margin: 0;
}
.toast-text p:nth-child(2){
    font-size: 0.9rem;
    color: var(--font-light);
    text-align: left;
    margin: 0;
}
.toast button{
    position: absolute;
    right: 1em;
    top: 1em;
    background: transparent;
    border: none;
    color: var(--font-clr);
    cursor: pointer;
}

.tutorial-tooltip-wrapper{
    position: relative;
    width: max-content;
    height: max-content;
}
.tutorial-tooltip{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.tutorial-tooltip::after{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 2px solid var(--third-clr);
    border-radius: 5px;
    z-index: 91;
}
.tutorial-tooltip::before{
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 90;
}
.tutorial-tooltip-text{
    position: absolute;
    width: 300px;
    background: var(--main-clr);
    padding: 1em;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    z-index: 91;
}
.tutorial-tooltip-text button{
    position: absolute;
    top: 0.5em;
    right: 1em;
    width: max-content;
    margin-top: 1em;
    background: transparent;
    border: none;
    color: var(--secondary-clr);
    border-radius: 5px;
    padding: 0;
    cursor: pointer;
}
.tutorial-tooltip-text h1{
    margin: 0;
    color: var(--intense-clr);
}
.tutorial-tooltip-text h3{
    line-height: 1.2em;
    color: var(--secondary-clr);
    font-size: 0.9rem;
    text-align: left;
    margin: 0;
    font-weight: 400;
}


/* Free account toast */
.free-account-toast{
    position: relative;
    width: 100%;
    background: var(--secondary-clr);
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 1em;
    border-radius: 5px;
    color: var(--main-clr);
}
.free-account-toast p{
    width: 90%;
    text-align: left;
    font-size: 0.8rem;
    color: var(--main-clr);
}
.free-account-toast-link{
    color: var(--third-clr);
    font-size: 1rem;
}
.free-account-toast button{
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--secondary-clr);
    cursor: pointer;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .toast{
        width: 100%;
        bottom: 0;
    }
    .free-account-toast{
        width: 95%;
    }
}
.settings-page{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em 1.5em;
    gap: 1em;
}
.settings-header{
    font-size: 1.2rem;
    text-align: left;
    margin: 0;
}


/* Top nav */
.settings-nav{
    width: 100%;
    display: flex;
    align-items: center;
}
.settings-nav-btn, .settings-nav-btn-active{
    width: max-content;
    position: relative;
    background: transparent;
    border: none;
    color: var(--font-light);
    padding: 0.5em 1em;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s;
}
.settings-nav-btn-active{
    background: var(--secondary-clr);
    color: var(--main-clr);
}


.settings-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-bottom: 5em;
}
.settings-wrapper p{
    text-align: left;
    color: var(--font-clr);
}


.settings-categories{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    background: var(--main-clr);
    border: 1px solid var(--border-clr);
    border-radius: var(--radius);
    padding: 1em;
}
.settings-categories-top{
    position: relative;
    display: flex;
    align-items: center;
    gap: 1em;
}
.settings-categories-sort{
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.settings-categories-sort p{
    font-size: 0.8rem;
    white-space: nowrap;
}
.settings-categories-sort select{
    border: 1px solid var(--border-clr);
    border-radius: var(--radius);
    padding: 0.5em;
}

/* Filter categories */
.settings-categories-wrapper{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-auto-rows: max-content;
}
.settings-category{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5em;
    align-items: center;
    overflow: hidden;
    padding: 0;
    margin: 0.5em 0;
}
.settings-category-top, .settings-category-sub{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    gap: 0.5em;
    align-items: center;
}
.settings-category-top-btn, .settings-category-top-btn-active{
    background: transparent;
    border: none;
    cursor: pointer;
}
.settings-category-top-btn-active{
    transform: rotate(180deg);
    transition: all 0.3s;
}
.settings-category-top input{
    background: red;
}
.settings-category-top label{
    text-transform: capitalize;
    color: var(--font-clr);
    white-space: nowrap;
    font-size: 0.9rem;
}
.settings-category-sub-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 2em;
}
.settings-category-sub label{
    font-size: 0.8rem;
}


.settings-categories-btns{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;
}
.settings-categories-btn{
    background: var(--secondary-clr);
    color: var(--main-clr);
    border: 1px solid var(--secondary-clr);
    border-radius: 5px;
    padding: 0.5em 1em;
    cursor: pointer;
    transition: all 0.3s;
}
.settings-categories-btn:hover{
    background: var(--main-clr);
    color: var(--secondary-clr);
}


/* Filter slider */
.profile-filter-slider-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.profile-filter-slider-div{
    width: 25%;
    display: flex;
    align-items: center;
    width: max-content;
    gap: 0.5em;
}
.profile-filter-slider-text{
    width: 22px;
    text-align: left;
    white-space: nowrap;
}
.profile-filter-slider{
    -webkit-appearance: none;
    background: var(--secondary-light);
    height: 5px;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin: 0;
}
.profile-filter-slider:nth-child(1){
    border-radius: 0 5px 5px 0;
}
.profile-filter-slider:nth-child(2){
    border-radius: 5px 0 0 5px;
}
.profile-filter-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid var(--third-clr);
    background: var(--main-clr);
    cursor: pointer;
    border-radius: 50%;
}
.profile-filter-slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04AA6D;
    cursor: pointer;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .settings-categories-title{
        display: none;
    }
    .settings-categories-wrapper{
        display: grid;
        grid-template-columns: auto;
        grid-auto-rows: max-content;
    }
}
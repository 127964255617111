.support-categories{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.support-categories h5{
    text-align: left;
    font-weight: 400;
    text-align: left;
    margin: 0;
    font-size: 1rem;
}

.support-categories-content{
    display: flex;
    flex-flow: row nowrap;
    gap: 1em;
    overflow-x: auto;
}
.support-categories-single{
    width: 50%;
    border-radius: 5px;
    box-shadow: var(--material-shadow);
    border: 1px solid var(--border-clr);
    display: flex;
    flex-direction: column;
}
.support-categories-single-top{
    display: flex;
    gap: 1em;
    color: var(--intense-clr);
    align-items: center;
    border-bottom: 1px solid var(--border-clr);
    padding: 1em;
}
.support-categories-single-top p{
    margin: 0;
}

.support-categories-single-sub{
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    padding: 1em;
}
.support-categories-single-sub small{
    color: var(--secondary-light);
    text-align: left;
    font-size: 0.9rem;
    margin: 0;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .support-categories{
        width: 100%;
    }
    .support-categories-single{
        width: 70%;
    }
}
/* Pending modal */
.backlinks-table-items-single-pending{
    position: fixed;
    bottom: 2em;
    left: 50%;
    transform: translateX(-50%);
    width: 40%;
    gap: 1em;
    border-radius: 5px;
    background: var(--secondary-clr);
    box-shadow: var(--material-shadow);
    padding: 0.8em 1em;
}
.backlinks-table-items-single-pending p{
    color: var(--main-light);
}
.backlinks-table-items-single-pending p span{
    font-weight: 700;
    color: var(--main-clr);
}
.backlinks-table-items-single-pending-link{
    color: var(--third-clr);
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
}

.backlinks-table-items-single-pending-btns{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2em;
}
.backlinks-table-items-single-pending-btns button{
    background: transparent;
    border: none;
    padding: 0;
    width: max-content;
    color: var(--main-clr);
    cursor: pointer;
}


/* Pending explanation modal */
.backlinks-table-items-single-pending-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    gap: 1em;
    border-radius: 5px;
    background: var(--main-light);
    box-shadow: var(--material-shadow-double);
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.backlinks-table-items-single-pending-modal h1{
    margin: 0;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
}
.backlinks-table-items-single-pending-modal p{
    line-height: 1.5em;
    color: var(--font-light);
}
.backlinks-table-items-single-pending-modal > button{
    position: absolute;
    top: 1em;
    right: 1em;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    /* Pending modal */
    .backlinks-table-items-single-pending{
        width: 90%;
    }
    .backlinks-table-items-single-pending p{
        width: 90%;
    }
}

@media only screen and (max-width: 480px){
    /* Pending modal */
    .backlinks-table-items-single-pending{
        width: 90%;
    }
    .backlinks-table-items-single-pending p{
        width: 90%;
    }
}
.companies{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    padding: 8em 8em 4em 8em;
}
.companies p{
    position: relative;
    font-size: 1rem;
    color: var(--font-landing-clr);
    white-space: nowrap;
}
.companies p::before{
    content: "";
    width: 300px;
    height: 1px;
    background: var(--font-landing-clr);
    position: absolute;
    right: -310px;
    top: 50%;
    transform: translateY(-50%);
}
.companies p::after{
    content: "";
    width: 300px;
    height: 1px;
    background: var(--font-landing-clr);
    position: absolute;
    left: -310px;
    top: 50%;
    transform: translateY(-50%);
}

.companies div{
    display: flex;
    align-items: center;
    gap: 2em;
}
.companies div img{
    width: 100px;
    opacity: 0.5;
}


@media only screen and (max-width: 980px){

}
@media only screen and (max-width: 480px){
    .new-landing header{
        padding: 0 2em;
    }
    .hero-sample, .hero-sample img{
        display: none;
    }
    .hero-content{
        width: 100%;
        padding: 0;
    }
}
.settings{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--main-light);
    border-left: 1px solid var(--border-clr);
    margin-bottom: 4em;
}
.settings-content-wrapper{
    display: flex;
}


.settings-content{
    width: 80%;
    height: 100%;
    background: var(--main-light);
    padding: 1.5em;
}
.settings-content > div{
    width: 100%;
    height: 100%;
}


/* Warning message */
.profile-message-wrapper{
    padding: 1.5em;
}
.profile-message{
    position: relative;
    width: 100%;
    background: var(--secondary-clr);
    border-radius: 5px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.profile-message h1{
    font-size: 1rem;
    margin: 0;
    text-align: left;
    color: var(--main-clr);
    font-weight: 500;
}
.profile-message p{
    color: var(--main-light);
    font-weight: 300;
    margin: 0;
    text-align: left;
    font-size: 0.8rem;
    line-height: 1.5em;
    width: 90%;
}

.profile-message-btns{
    display: flex;
    align-items: center;
    margin-top: 1em;
}
.profile-message-close-btn{
    width: max-content;
    text-decoration: underline;
    background: transparent;
    border: none;
    color: var(--main-clr);
    padding: 0.5em 1em;
    border-radius: 5px;
    cursor: pointer;
}
.profile-message-close-btn:hover{
    color: var(--third-clr);
}

.footer{
    width: 100%;
    height: max-content;
    background: var(--intense-clr);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2em;
}

.footer-top{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4em;
    align-items: center;
    padding: 5em 2em 0 2em;
}
.footer-top h1{
    text-align: left;
    color: white;
}
.footer-top-divs{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.footer-top-divs div{
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.footer-top-divs div h5{
    color: white;
    text-align: left;
    font-weight: 400;
    font-size: 1.1rem;
    margin: 0;
}
.footer-top-links{
    text-decoration: none;
    text-align: left;
    font-size: 0.8rem;
    color: var(--main-light);
    font-weight: 300;
    margin: 0;
}
.footer-top-links:hover{
    color: var(--main-clr);
}


.footer-bottom{
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid white;
    padding: 0 2em;
}
.footer-bottom-copyright{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.3em;
    font-size: 0.8rem;
    color: white;
}
.footer-bottom-copyright p, .footer-bottom-socials p{
    margin: 0;
}
.footer-bottom-legal{
    display: flex;
    align-items: center;
    gap: 1em;
}
.footer-bottom-socials-wrapper{
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: white;
}
.footer-bottom-socials{
    font-size: 1.4rem;
    color: var(--main-clr);
}
.footer-bottom-links{
    text-decoration: none;
    color: rgb(203, 203, 203);
    display: flex;
    align-items: center;
    font-size: 0.8rem;
}
.footer-bottom-links:hover{
    color: var(--main-clr);
}


/* Dashboard footer */
.dashboard-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dashboard-footer-copyright{
    display: flex;
    align-items: center;
    color: var(--font-light);
    font-size: 0.8rem;
}
.dashboard-footer-legal{
    display: flex;
    align-items: center;
    gap: 1em;
}
.dashboard-footer-legal-links{
    color: var(--font-clr);
    font-size: 0.8rem;
}


@media only screen and (max-width: 480px){
    .footer{
        padding: 1em;
    }
    .footer-top{
        justify-content: space-between;
        padding: 5em 0 0 0;
    }
    .footer-bottom{
        padding: 1em 0;
        flex-direction: column;
        align-items: center;
    }
    .footer-bottom-copyright{
        width: 100%;
        justify-content: center;
    }
    .footer-bottom-socials{
        display: none;
    }

    .dashboard-footer-copyright{
        font-size: 0.7rem;
    }
    .dashboard-footer-legal-links{
        font-size: 0.7rem;
    }
}
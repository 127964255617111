.dashboard-page{
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 10vh;
}

.dashboard-page-container{
    position: relative;
    width: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background: var(--main-clr);
    overflow-x: hidden;
}
.dashboard-page-container-content{
    width: 84%;
    height: 90vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--main-light);
}
.dashboard-page-container::-webkit-scrollbar {
    width: 10px;
}  
.dashboard-page-container::-webkit-scrollbar-track {
    background: rgb(232, 220, 220);
}
.dashboard-page-container::-webkit-scrollbar-thumb {
    background: var(--secondary-clr);
}
.dashboard-page-container::-webkit-scrollbar-thumb:hover {
    background: var(--intense-clr)
}


.dashboard-error-page{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 8em;
}
.dashboard-error-page h1{
    font-size: 2rem;
    font-weight: 400;
    color: var(--font-title-clr);
    margin: 0;
}
.dashboard-error-page p{
    color: var(--font-landing-clr);
    margin: 0;
}
.dashboard-error-page button{
    background: transparent;
    border: none;
    color: var(--secondary-clr);
    cursor: pointer;
    font-size: 1rem;
    text-transform: uppercase;
}
.dashboard-error-page button:hover{
    color: var(--intense-clr);
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    .profile-dashboard{
        width: 100%;
    }
}

@media only screen and (max-width: 480px){
    .logo h1{
        display: none;
    }
    .dashboard-page-container-content{
        width: 100%;
    }
}
/* Tooltip */
.tootip-wrapper {
    display: inline-block;
    position: relative;
}
  
.tooltip-text,
.tooltip-text-top,
.tooltip-text-bottom,
.tooltip-text-left,
.tooltip-text-right {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: var(--main-clr);
    background: rgb(18, 17, 35);
    font-size: 0.8rem;
    line-height: 1.3em;
    z-index: 100;
    text-align: center;
    white-space: wrap;
    max-width: 200px;
    min-width: 150px;
    pointer-events: none;
}
.tooltip-text::before {
    content: " ";
    left: 50%;
    transform: translateX(-50%);
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
}
.tooltip-text-top{
    bottom: calc(var(--tooltip-margin) * 1);
}   
.tooltip-text-top::before{
    top: 100%;
    border-top-color: rgb(18, 17, 35);
}
.tooltip-text-bottom{
    top: calc(var(--tooltip-margin) * 1);
}
.tooltip-text-bottom::before {
    bottom: 100%;
    border-bottom-color: var(--tooltip-background-color);
}
.tooltip-text-left{
    left: auto;
    right: calc(30% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}
.tooltip-text-left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--tooltip-background-color);
}
.tooltip-text-right{
    left: calc(30% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}
.tooltip-text-right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--tooltip-background-color);
}
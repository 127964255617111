.outlinks-stats{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
}
.outlinks-stats-single{
    min-width: 32%;
    height: 100%;
    padding: 1em;
    border-radius: 5px;
    background: var(--main-clr);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1em;
}
.outlinks-stats-single-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 1em;
}
.outlinks-stats-single-text div{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.outlinks-stats-single-text h6{
    font-weight: 400;
    width: 100%;
    color: black;
    text-align: left;
    margin: 0;
    font-size: 1rem;
}
.outlinks-stats-single-text small{
    width: 100%;
    color: var(--font-light);
    text-align: left;
    margin: 0;
    font-size: 0.8rem;
}
.outlinks-stats-single-text p{
    color: var(--intense-clr);
    font-weight: 500;
    text-align: left;
    margin: 0.5em 0 0 0;
    font-size: 1.2rem;
}
.outlinks-stats-single > div:nth-child(2){
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}
.outlinks-stats-single > div:nth-child(2) button{
    background: transparent;
    border: none;
}
.outlinks-stats-single-percentage, .outlinks-stats-single-percentage-negative{
    display: flex;
    align-items: center;
    font-weight: 600;
    color: var(--success-clr);
    font-size: 0.8rem;
}
.outlinks-stats-single-percentage-negative{
    color: red;
}

.privacy{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.privacy-wrapper{
    width: 100%;
    display: flex;
}
.privacy-sidebar{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 8em 4em 4em 4em;
}
.privacy-sidebar a{
    text-decoration: none;
    font-size: 1.1rem;
    color: var(--font-landing-clr);
    text-align: left;
    cursor: pointer;
}
.privacy-sidebar a:hover{
    color: var(--font-title-clr);
}

.privacy-content{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 8em;
}
.privacy-content h1{
    margin: 0 0 1em 0;
    text-align: left;
    font-weight: 500;
    font-size: 3rem;
    color: var(--font-title-clr);
}
.privacy-content p{
    text-align: left;
    color: var(--font-landing-clr);
    margin: 0;
    line-height: 1.5em;
}
.privacy-content small{
    text-align: left;
    color: var(--font-light);
}

.privacy-content-single{
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 2em;
}
.privacy-content-single h5{
    color: var(--font-title-clr);
    text-align: left;
    font-size: 1rem;
    margin: 0;
}
.privacy-content-single h5 span{
    margin-right: 0.5em;
}
.privacy-content-single p{
    color: var(--font-landing-clr);
    margin: 0;
    text-align: left;
    line-height: 1.5em;
}
.privacy-content-single li{
    list-style-type: none;
    text-align: left;
    color: var(--font-light);
    font-size: 0.9rem;
}
.support-articles{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    padding: 2em;
    padding-top: 6em;
}


.support-articles-content{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.support-articles-content h1{
    text-align: center;
    margin: 0;
    color: var(--font-clr);
    font-size: 1.5rem;
    font-weight: 300;
}
.support-articles-content p{
    text-align: center;
    font-size: 1rem;
    color: var(--font-light);
    margin: 0;
    line-height: 1.5em;
}
.support-articles-content button{
    background: transparent;
    border: none;
    width: max-content;
    color: var(--secondary-clr);
    cursor: pointer;
    padding: 0;
    font-size: 1.2rem;
}


.support-articles-contact p{
    text-align: left;
    color: var(--font-light);
    font-size: 0.9rem;
}
.support-articles-contact button{
    background: transparent;
    color: var(--secondary-clr);
    width: max-content;
    border: none;
    padding: 0;
    font-size: 1rem;
    cursor: pointer;
}
.coming-soon{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
    padding: 2em;
}
.coming-soon h1{
    color: var(--font-clr);
    margin: 0;
    text-align: center;
}
.coming-soon p{
    color: var(--font-light);
    margin: 0;
    line-height: 1.5em;
}
.coming-soon-link{
    padding: 0.5em 1em;
    text-decoration: none;
    background: var(--secondary-clr);
    border-radius: 5px;
    color: var(--main-clr);
}

@media only screen and (max-width: 480px){

}
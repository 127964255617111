.login-page{
    width: 100%;
    min-height: 100vh;
    background: var(--main-clr);
    padding: 6em 2em 2em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
}
.login-page-p{
    width: 60%;
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    color: var(--font-title-clr);
    margin: 0;
}
.login-page small{
    width: 50%;
    text-align: center;
    font-size: 0.8rem;
    color: var(--font-landing-clr);
    margin: 0;
}


/* Login form */
#login-form{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 1em;
}

.login-input-wrapper{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5em;
    border: 1px solid var(--border-clr);
    border-radius: var(--radius);
    padding: 0.2em 0.4em;
}
.login-input-wrapper:focus-within{
    border-bottom: 2px solid var(--secondary-clr);
}

.login-text-input, .login-password-input{
    width: 90%;
    background: transparent;
    border: none;
    padding: 1em 2em;
    outline: none;
}
#login-form label{
    font-size: 0.8rem;
}
.login-icons{
    width: 10%;
    color: grey;
}
.form-btn{
    color: var(--main-clr);
    background: var(--secondary-clr);
    border: none;
    border-radius: var(--radius);
    padding: 1em 2em;
    cursor: pointer;
    transition: all 0.3s;
    will-change: background;
}
.form-btn:hover{
    background: var(--intense-clr);
}
.keep-signed-btn{
    background: transparent;
    border: none;
    width: max-content;
}
.login-checkbox{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.show-password-btn{
    position: absolute;
    right: 0.6em;
    background: transparent;
    color: var(--font-clr);
    font-size: 1rem;
    border: none;
    cursor: pointer;
}


/* Forgot pass */
.forgot-pass-btn{
    width: 40%;
    text-align: left;
    text-decoration: none;
    font-size: 0.9rem;
    background: transparent;
    border: none;
    color: var(--secondary-clr);
    cursor: pointer;
    transition: all 0.3s;
    will-change: color;
}
.forgot-pass-btn:hover{
    color: var(--intense-clr);
}


/* Register redirect */
.login-register-redirect{
    margin-top: 2em;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.login-register-redirect p{
    color: var(--font-light);
    font-size: 0.8rem;
}
.login-register-redirect-link{
    color: var(--secondary-clr);
    font-size: 0.9rem;
    text-decoration: none;
    white-space: nowrap;
}


.registration-complete-popup{
    position: absolute;
    bottom: 2em;
    left: 50%;
    transform: translateX(-50%);
    background: red;
}


.login-terms{
    margin-top: 6em;
    font-size: 0.8rem;
    color: var(--font-light);
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    .login-wrapper{
        display: none;
    }
}
@media only screen and (max-width: 480px){
    .login-page-p{
        width: 100%;
    }
    #login-form{
        width: 100%;
    }
    .forgot-pass-btn{
        width: 100%;
    }
}
/* Conversations sidebar */
.conversation-sidebar{
    position: relative;
    width: 32%;
    height: 100%;
    border: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    border-right: 1px solid var(--border-clr);
    background: var(--main-clr);
    border-left: 1px solid var(--border-clr);
} 
.conversation-sidebar-shrink-btn{
    position: absolute;
    right: 1em;
    top: 1.5em;
    background: transparent;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transform: rotate(90deg);
}
.conversation-sidebar-empty{
    text-align: left;
    color: var(--secondary-clr);
    width: 100%;
    padding: 0 1em;
    font-size: 14px;
    user-select: none;
}

/* Sidebar top */
.conversation-sidebar-top{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 1em;
}
.conversation-sidebar-top-btn, .conversation-sidebar-top-btn-active{
    background: var(--main-light);
    color: var(--font-clr);
    font-weight: 500;
    border: none;
    padding: 0.5em 1em;
    cursor: pointer;
}
.conversation-sidebar-top button:nth-child(1){
    border-radius: 5px 0 0 5px;
}
.conversation-sidebar-top button:nth-child(2){
    border-radius: 0 5px 5px 0;
}
.conversation-sidebar-top-btn-active{
    background: var(--third-clr);
    color: var(--secondary-clr);
}

.conversation-sidebar-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5em;
    margin-bottom: 1em;
}
.conversation-sidebar-label{
    width: 100%;
    text-align: left;
    padding: 0 1em;
    font-weight: 500;
    color: var(--font-light);
}
.conversation-sidebar-header-btns{
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.conversation-sidebar-header-btns button:nth-child(1){
    width: 22px;
    height: 22px;
    background: var(--main-clr);
    border: 1px solid var(--border-clr);
    border-radius: 50%;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.conversation-sidebar-header-btns button:nth-child(2){
    background: transparent;
    border: none;
    cursor: pointer;
}
.conversation-sidebar-search-wrapper{
    width: 90%;
}


/* Conversations sidebar single */
.conversation-sidebar-conversations-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    overflow-y: auto;
}
.conversation-sidebar-conversations-wrapper::-webkit-scrollbar {
    width: 10px;
    display: none;
}
.conversation-sidebar-conversations-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.conversation-sidebar-conversations-wrapper::-webkit-scrollbar-thumb {
    background: #888;
}
.conversation-sidebar-conversations-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.conversation-sidebar-single, .conversation-sidebar-single-active {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 1em 0.5em;
    border-bottom: 1px solid var(--border-clr);
    cursor: pointer;
    transition: background 0.1s;
}
.conversation-sidebar-single-active{
    background: var(--main-light);
}
.conversation-sidebar-single:hover, .conversation-sidebar-single-active:hover{
    background: var(--third-clr);
}
.conversation-sidebar-single-img {
    width: 35px;
    height: 35px;
    background: var(--secondary-light);
    color: var(--main-clr);
    border-radius: 50%;
    /* border: 2px solid var(--font-clr); */
    box-shadow: var(--material-shadow);
    display: flex;
    justify-content: center;
    align-items: center;
}

.conversation-sidebar-single-title{
    position: relative;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 0.2em;
}
.conversation-sidebar-single-title-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.conversation-sidebar-single-name{
    color: black;
    font-size: 14px;
    text-transform: capitalize;
}
.conversation-sidebar-single-name span{
    color: var(--secondary-clr);
    text-transform: none;
}
.conversation-sidebar-single-title-div small{
    font-size: 12px;
    color: var(--font-light);
    pointer-events: none;
}
.conversation-sidebar-single-message{
    text-align: left;
    color: var(--font-light);
    font-size: 12px;
}
.conversation-sidebar-single-message-unopened{
    text-align: left;
    color: var(--secondary-clr);
    font-weight: 500;
    font-size: 12px
}
.conversation-sidebar-single-message-new{
    position: absolute;
    width: 17px;
    height: 17px;
    right: 0px;
    bottom: 0;
    background: var(--fail-clr);
    border-radius: 50%;
    color: var(--main-clr);
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .backlink-connections-sidebar, .outlink-connections-sidebar{
        width: 100%;
        height: 100%;
    }
}
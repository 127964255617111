.wailist-complete{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 1em;
}
.wailist-complete h1{
    margin: 0;
    font-size: 2rem;
    font-weight: 500;
    color: var(--font-title-clr);
    text-align: center;
}
.wailist-complete > p{
    width: 40%;
    margin: 0;
    color: var(--font-light);
}

#waitlist-register-form{
    display: flex;
    flex-direction: column;
    gap: 1em;   
    margin-top: 2em;
}

@media only screen and (max-width: 980px){
    .about-content{
        padding: 4em;
    }
    .about h1{
        width: 100%;
    }
}
@media only screen and (max-width: 480px){
    .wailist-complete > p{
        width: 100%;
    }
}
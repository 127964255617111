.admin-sidebar{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 17%;
    height: 90%;
    background: var(--main-clr);
    border-right: 1px solid var(--border-clr);
}


/* Sidebar bottom */
.admin-sidebar-bottom{
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 2em 1em;
}
.admin-sidebar-bottom h1{
    font-size: 1rem;
    color: var(--font-clr);
    margin: 1em 0 0 0;
    text-align: left;
}


.admin-sidebar-btn{
    text-decoration: none;
    position: relative;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.4em 0.4em 0.4em 1em;
    color: var(--font-clr);
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;
}
.admin-sidebar-btn:hover{
    background: rgb(95, 95, 255);
    color: white;
}
.admin-sibebar-btn-active{
    text-decoration: none;
    position: relative;
    background: linear-gradient(to left, rgba(0, 17, 255, 0), #ba8d107e);
    border: none;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.4em 0.4em 0.4em 1em;
    color: var(--font-clr);
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;
}

.waitlist-btn{
    width: 100%;
    font-size: 0.8rem;
    cursor: pointer;
}
.conversation-preferences{
    position: relative;
    width: 68%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    padding: 1em;
    overflow-y: auto;
    overflow-x: hidden;
}
.conversation-preferences::-webkit-scrollbar {
    width: 10px;
}
.conversation-preferences::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.conversation-preferences::-webkit-scrollbar-thumb {
    background: #888;
}
.conversation-preferences::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.conversation-preferences-shrink-btn{
    width: 100%;
    border-radius: 25px 25px 0 0;
    padding: 0.5em;
    border: none;
}


.conversation-preferences-top{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 1em;
    box-shadow:  5px 5px 29px #e6e6e6,
             -5px -5px 29px #efefef;
    border-radius: 15px;
    background: var(--main-clr);
}
.conversation-preferences-top div:nth-child(1){
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: var(--secondary-clr);
    color: var(--main-clr);
    display: flex;
    align-items: center;
    justify-content: center;
}
.conversation-preferences-top div:nth-child(2){
    display: flex;
    flex-direction: column;
}
.conversation-preferences-top div:nth-child(2) p{
    text-transform: capitalize;
    text-align: left;
    color: var(--font-clr);
    font-size: 14px;
}
.conversation-preferences-top div:nth-child(2) small{
    text-align: left;
    color: var(--font-light);
    font-size: 12px;
}
.conversation-preferences-top div:nth-child(2) span{
    color: var(--secondary-clr);
    text-transform: lowercase;
}
.conversation-preferences-top div:nth-child(3){
    position: absolute;
    right: 1em;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.conversation-preferences-top-icon{
    color: var(--success-clr);
    font-size: 24px;
}

/* Top options */
.conversation-preferences-top-options-wrapper{
    position: relative;
}
.conversation-preferences-top-options-btn{
    cursor: pointer;
    background: transparent;
    border: none;
}
.conversation-preferences-top-options{
    position: absolute;
    top: 30px;
    right: 10px;
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    background: var(--main-clr);
    border-radius: 5px;
    overflow: hidden;
    z-index: 2;
}
.conversation-preferences-top-options button{
    font-size: 12px;
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--border-clr);
    padding: 0.4em 0.8em;
    white-space: nowrap;
    display: flex;
    cursor: pointer;
}
.conversation-preferences-top-options button:hover{
    background: var(--secondary-clr);
    color: var(--main-clr);
}


/* Intro */
.conversation-preferences-intro{
    display: flex;
    flex-direction: column;
    box-shadow:  5px 5px 29px #e6e6e6,
    -5px -5px 29px #efefef;
    border-radius: 15px;
    background: var(--main-clr);
    gap: 1em;
    padding: 2em;
}
.conversation-preferences-intro h1{
    width: 100%;
    font-size: 1.2rem;
    color: black;
    font-weight: 300;
    margin: 0;
    text-align: left;
}
.conversation-preferences-intro h1 span{
    color: var(--secondary-clr);
}
.conversation-preferences-intro p{
    color: var(--font-light);
    line-height: 1.5em;
    font-size: 0.9rem;
    margin: 0;
    text-align: left;
}
.conversation-preferences-intro p span{
    text-transform: capitalize;
}
.conversation-preferences-intro-btns{
    margin-top: 1em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.conversation-preferences-intro-ok-btn{
    position: relative;
    width: max-content;
    background: var(--secondary-clr);
    color: var(--main-clr);
    border: none;
    border-radius: 5px;
    padding: 0.5em 1em;
    cursor: pointer;
}
.conversation-preferences-intro-ok-btn:hover{
    background: var(--intense-clr);
}
.conversation-preferences-intro-hide-btn{
    display: flex;
    align-items: center;
    gap: 0.3em;
    font-size: 12px;
    color: var(--font-light);
}


/* Settings */
.conversation-preferences-settings-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 1em;
    box-shadow:  5px 5px 29px #e6e6e6,
             -5px -5px 29px #e6e6e6;
    border-radius: 25px;
    background: var(--main-clr);
}
.conversation-preferences-settings-wrapper h1{
    font-size: 14px;
    text-align: left;
    font-weight: normal;
    margin: 0;
}

.conversation-preferences-settings{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.2em;
    padding: 1em;
}
.conversation-preferences-settings-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5em;
}
.conversation-preferences-settings > p{
    text-align: left;
    color: var(--font-clr);
    font-size: 1rem;
    font-weight: 500;
}
.conversation-preferences-settings small{
    text-align: left;
    font-size: 0.8rem;
    color: var(--font-light);
    margin-bottom: 2em;
}
.conversation-preferences-settings small span{
    text-transform: capitalize;
}

.conversation-preferences-settings-radiobtns-wrapper{
    display: flex;
    gap: 1em;
}
.conversation-preferences-settings-radiobtns{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3em;
}
.conversation-preferences-settings-radiobtns p{
    color: var(--font-clr);
    text-align: left;
    font-size: 14px;
    margin: 0;
}
.conversation-preferences-settings-radiobtns > div{
    display: flex;
    flex-flow: row wrap;
    gap: 0.5em;
}
.conversation-preferences-settings-radiobtns > div > div{
    display: flex;
    align-items: flex-start;
    color: var(--font-light);
    font-size: 14px;
}

.conversation-preferences-settings-input-wrapper{
    display: flex;
    gap: 1em;
}
.conversation-preferences-settings-input{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3em;
}
.conversation-preferences-settings-input > div{
    color: var(--font-clr);
    text-align: left;
    font-size: 14px;
    margin: 0;
}
.conversation-preferences-settings-input input{
    width: 100%;
    outline: none;
    background: transparent;
    border: 2px solid var(--main-light);
    background: var(--main-light);
    border-radius: 5px;
    padding: 1em;
    color: var(--font-light);
}
.conversation-preferences-settings-input input:focus{
    background: var(--secondary-clr);
    color: var(--main-clr);
}


.conversation-preferences-settings-finish{
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    font-size: 14px;
    margin-top: 1em;
    overflow: hidden;
}
.conversation-preferences-settings-finish button{
    width: max-content;
    background: var(--secondary-clr);
    border: none;
    padding: 0.5em 4em;
    color: var(--main-clr);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}
.conversation-preferences-settings-finish button:hover{
    background: var(--intense-clr);
}
.conversation-preferences-settings-finish h6{
    margin: 0;
    color: var(--font-clr);
    font-weight: 400;
    text-align: left;
    white-space: wrap;
}


/* Connection approval  */
.conversation-preferences-approval{
    width: 100%;
    box-shadow:  5px 5px 29px #e6e6e6,
    -5px -5px 29px #efefef;
    border-radius: 15px;
    background: var(--main-clr);
    display: flex;
    flex-direction: column;
    padding: 1em;
    gap: 1em;
}
.conversation-preferences-approval-top{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.conversation-preferences-approval-top > div{
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.conversation-preferences-approval-top-help{
    position: relative;
    background: var(--font-light);
    border: none;
    color: var(--main-clr);
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    cursor: help;
}
.conversation-preferences-approval-top-help:hover::after{
    opacity: 1;
}
.conversation-preferences-approval-top-help::after{
    content: 'You can approve or reject this connection, the end user will not be notified either way. Once approved you can start communicating with each other to place a link.';
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    background: var(--font-light);
    font-size: 0.7rem;
    border-radius: 5px;
    text-align: left;
    padding: 1em;
    opacity: 0;
    pointer-events: none;
}
.conversation-preferences-approval-top p{
    color: black;
    text-align: left;
}
.conversation-preferences-approval-top small{
    color: var(--font-light);
    font-size: 0.85rem;
    line-height: 1.5em;
    text-align: left;
}
.conversation-preferences-approval-top span{
    text-transform: capitalize;
}
.conversation-preferences-approval-btns{
    width: max-content;
    display: flex;
    height: max-content;
    gap: 0.5em;
}
.conversation-preferences-reject-btn{
    background: transparent;
    border: 1px solid var(--secondary-clr);
    color: var(--secondary-clr);
    border-radius: 5px;
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.conversation-preferences-reject-btn:hover{
    background: var(--third-clr);
    border-color: var(--third-clr);
}
.conversation-preferences-approve-btn{
    background: var(--secondary-clr);
    border: none;
    color: var(--main-clr);
    padding: 0.5em 1em;
    border-radius: 5px;
    cursor: pointer;
}
.conversation-preferences-approve-btn:hover{
    background: var(--intense-clr);
}


/* Participant */
.conversation-preferences-participant{
    position: relative;
    width: 100%;
    padding: 0.5em 2em;
    box-shadow:  5px 5px 29px #e6e6e6,
             -5px -5px 29px #e6e6e6;
    border-radius: 15px;
    background: var(--main-clr);
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding-bottom: 1.5em;
}

.conversation-preferences-participant h1{
    font-size: 0.9rem;
    text-align: left;
    font-weight: 500;
    color: var(--font-clr);
    margin: 0;
}
.conversation-preferences-participant-p{
    max-width: 95%;
    font-size: 0.8rem;
    text-align: left;
    color: var(--font-light);
}
.conversation-preferences-participant-p span{
    color: black;
    font-weight: 500;
}
.conversation-preferences-participant h1 span{
    text-transform: capitalize;
}
.conversation-preferences-participant-empty{
    width: 100%;
    color: var(--font-light);
    text-align: left;
    font-size: 12px;
}

.conversation-preferences-participant-btns{
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-top: 1em;
}
.conversation-preferences-participant-apply{
    width: max-content;
    background: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
    color: var(--main-clr);
    padding: 0.5em 1em;
    border-radius: 5px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: background 0.3s;
}
.conversation-preferences-participant-apply:hover{
    background: var(--intense-clr);
}
.conversation-preferences-participant-close-btn{
    background: var(--main-light);
    border: none;
    color: var(--secondary-clr);
    padding: 0.5em;
    border-radius: 5px;
    cursor: pointer;
}
.conversation-preferences-participant-close-btn:hover{
    background: var(--third-clr);
}

/* Completed */
.conversation-preferences-archived{
    border-radius: 15px;
    width: 68%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3em;
}
.conversation-preferences-archived img{
    width: 40%;
    height: 40%;
}
.conversation-preferences-archived h1{
    font-size: 16px;
    color: var(--font-clr);
}
.conversation-preferences-archived p{
    color: var(--font-light);
    font-size: 14px;
    width: 70%;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .conversation-preferences, .conversation-preferences-archived{
        padding: 0.5em 0.5em 2em 0.5em;
        width: 100%;
        border-radius: 25px 25px 0 0;
        box-shadow: var(--material-shadow);
        border: 1px solid var(--border-clr);
    }
    .conversation-preferences::-webkit-scrollbar{
        display: none;
    }
    .conversation-preferences-settings{
        padding: 0;
    }
    .conversation-preferences-settings-text{
        text-align: left;
    }
    .conversation-preferences-settings-finish, .conversation-preferences-settings-finish button{
        width: 100%;
    }

    .conversation-preferences-archived p{
        width: 100%;
    }
}
.website-details{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2em 1em 6em 1em;
    gap: 2em;
    border-left: 1px solid var(--border-clr);
    background: var(--main-light);
}
.website-details-empty{
    width: 100%;
    height: 100%;
}
.website-details-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.website-details-header-btn{
    width: max-content;
    padding: 0.5em 1em;
    border: 1px solid var(--secondary-clr);
    border-radius: 5px;
    font-size: 0.8rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.website-details-header-btn:hover{
    background: var(--secondary-clr);
    color: var(--main-clr);
}
.website-details h1{
    color: var(--font-clr);
    text-align: left;
    margin: 0;
    font-size: 1.1rem;
    font-weight: 400;
}
.website-details h1 span{
    font-size: 1rem;
    font-weight: 300;
}


.website-details-stats{
    width: 100%;
    height: 200px;
    background: var(--main-clr);
    margin-bottom: 2em;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .website-details-header{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1em;
    }
}
.landing header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12em 2em 8em 8em;
}


.hero-content{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1em;
    padding-right: 5em;
}
.hero-content h1{
    text-align: left;
    font-size: 3rem;
    width: 100%;
    line-height: 1.2em;
    color: var(--font-title-clr);
    margin: 0;
}
.hero-content h1 span{
    color: var(--secondary-clr);
}
.hero-content p{
    width: 100%;
    color: var(--font-landing-clr);
    margin: 0;
    text-align: left;
}
.hero-content small{
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.5em;
    text-decoration: underline;
    font-size: 1rem;
}


/* Waitlist */
#hero-waitlist-form{
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 2em;
}
#hero-waitlist-form > p{
    color: var(--font-light);
    font-size: 0.9rem;
    margin: 0;
}

#hero-waitlist-form > div{
    display: flex;
    align-items: center;
    gap: 1em;
}
#hero-waitlist-form > div button{
    background: var(--secondary-clr);
    border: none;
    padding: 1em 2em;
    color: var(--main-clr);
    border-radius: 5px 25px 25px 5px;
    white-space: nowrap;
    cursor: pointer;
}
#hero-waitlist-form > div button:hover{
    background: var(--intense-clr);
}
#hero-waitlist-form-error, #hero-waitlist-form-success{
    background: var(--success-clr);
    padding: 0.3em 0.6em;
    color: var(--main-clr);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#hero-waitlist-form-error{
    background: var(--fail-clr);
}
#hero-waitlist-email-error{
    text-align: left;
    color: var(--fail-clr);
    font-size: 0.9rem;
}


.hero-sample{
    position: relative;
    width: 50%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}
#hero-shape{
    position: absolute;
    top: -150%;
    right: -100%;
    z-index: -1;
    transform: scale(0.5) rotate(180deg);
}
#hero-shape2{
    display: none;
    position: absolute;
    top: -150%;
    right: -50%;
    z-index: -1;
    transform: rotate(45deg);
}

.hero-sample-sidebar{
    width: 400px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1em;
    background: white;
    box-shadow: var(--material-shadow);
    padding: 1em;
    border-radius: 5px;
    display: none;

}
.hero-sample-sidebar-div{
    display: flex;
    align-items: center;
    gap: 1em;
    font-size: 0.8rem;
    color: var(--font-clr);
}

.hero-sample-growth{
    width: max-content;
    position: absolute;
    top: -3em;
    right: 0;
    border-radius: 5px;
    background: var(--intense-clr);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    padding: 0.5em;
    display: flex;
    align-items: center;
    gap: 3em;
    color: var(--success-clr);
    animation: scales 3s ease-in-out infinite alternate;
    -webkit-animation: scales 3s ease-in-out infinite alternate;
}
.hero-sample-growth span{
    font-size: 0.9rem;
    color: var(--main-clr);
}

.hero-sample-connection{
    width: 130%;
    background: var(--intense-clr);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    position: absolute;
    bottom: -4em;
    left: 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 0.3em;
    padding: 0.6em;
    color: var(--main-clr);
    animation: scales2 3s ease-in-out infinite alternate;
    -webkit-animation: scales2 3s ease-in-out infinite alternate;
    z-index: 13;
}
.hero-sample-connection > div{
    border: 1px solid var(--border-clr);
    background: white;
    width: 80%;
    height: 1.5em;
    border-radius: 5px;
}

.hero-sample-dash{
    position: relative;
    width: 400px;
    background: var(--main-light);
    box-shadow: var(--material-shadow);
    border-radius: 5px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    z-index: -1;
    display: none;
}
.hero-sample-dash-div{
    width: 100%;
    height: 50%;
    background: var(--main-clr);
    box-shadow:  var(--material-shadow);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em;
}
.hero-sample-dash-div > div{
    width: 100%;
    height: 10px;
    background: var(--main-light);
    border: 1px solid var(--border-clr);
    border-radius: 5px;
}
.hero-sample-dash-div span{
    text-align: left;
    font-size: 0.9rem;
}
.hero-sample-dash-div small{
    font-size: 0.6rem;
    text-align: left;
}
.hero-sample-dash-div h5{
    text-align: left;
    margin: 0;
    color: var(--secondary-clr);
    font-size: 0.9rem;
    font-weight: 500;
}

.hero-sample-avatar, .hero-sample-avatar2{
    position: absolute;
    top: -3em;
    right: -3em;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    overflow: hidden;
    z-index: 4;
    background: var(--main-clr);
}
.hero-sample-avatar2{
    left: -3em;
    top: 50%;
}
.hero-sample-avatar img, .hero-sample-avatar2 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-sample-message{
    position: absolute;
    bottom: 0em;
    right: 0em;
    background: var(--main-clr);
    border: 2px solid var(--secondary-clr);
    box-shadow:  var(--material-shadow);
    width: 2em;
    height: 2em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    color: var(--secondary-clr);
    display: none;
}
.hero-sample-message span{
    position: relative;
}
.hero-sample-message span::before{
    content: "3";
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: var(--fail-clr);
    color: var(--main-clr);
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: scales2 1s ease-in-out infinite alternate;
    -webkit-animation: scales2 1s ease-in-out infinite alternate;
}

@keyframes scales {
    0% {
        transform: translateY(-10px) translateX(-30px);
    }
    100% {
        transform: translateX(0) translateY(0);
    }
}
@keyframes scales2 {
    0% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateX(0) translateY(0);
    }
}

.hero-sample-ui{
    width: 90%;
    height: 300px;
    background: white;
    display: flex;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    border-radius: 5px;
    transform: perspective(700px) rotateY(-35deg);
}
.hero-sample-ui-sidebar{
    width: 25%;
    background: var(--main-light);
    border-right: 1px solid var(--border-clr);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    border-radius: 5px;
    transform: translateX(-30px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5em;
    animation: scales2 3s ease-in-out infinite alternate;
    -webkit-animation: scales2 3s ease-in-out infinite alternate;
}
.hero-sample-ui-sidebar-top{
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.hero-sample-ui-sidebar-top > div{
    width: 100%;
    height: 10px;
    display: flex;
    gap: 0.5em;
}
.hero-sample-ui-sidebar-top > div > div:nth-child(1){
    width: 10%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--secondary-clr);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
.hero-sample-ui-sidebar-top > div > div:nth-child(2){
    width: 90%;
    height: 100%;
    background-color: var(--intense-clr);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
.hero-sample-ui-sidebar-bottom > div{
    width: 100%;
    height: 10px;
    background-color: var(--intense-clr);
    border-radius: 15px;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}


.hero-sample-ui-content{
    width: 75%;
    display: flex;
    flex-direction: column;
    padding: 0.5em;
}
.hero-sample-ui-content-nav{
    width: 100%;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    background: var(--main-light);
    border-radius: 15px;    
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.2em 0.4em;
    gap: 0.5em;
}
.hero-sample-ui-content-nav > div:nth-child(1), .hero-sample-ui-content-nav > div:nth-child(2){
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--secondary-clr);
}
.hero-sample-ui-content-nav > div:nth-child(2){
    width: 15px;
    height: 15px;
}

.hero-sample-ui-content-dash{
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 0.5em;
}
.hero-sample-ui-content-top, .hero-sample-ui-content-bottom{
    display: flex;
    align-items: center;
    gap: 1em;
    height: 35%;
}
.hero-sample-ui-content-top > div{
    width: 20%;
    height: 100%;
    background: var(--main-clr);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    border-radius: 5px;
}
.hero-sample-ui-content-top > div:nth-child(1){
    width: 50%;
    transform: translateY(-10px);
    animation: scales2 3s ease-in-out infinite alternate;
    -webkit-animation: scales2 3s ease-in-out infinite alternate;
}
.hero-sample-ui-content-top > div > div:nth-child(1){
    width: 100%;
    height: 100%;
}
.hero-sample-ui-content-top > div > div:nth-child(1) img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.hero-sample-ui-content-top > div:nth-child(2), .hero-sample-ui-content-top > div:nth-child(3){
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    gap: 0.4em;
    padding: 0.5em;
}
.hero-sample-ui-content-top > div:nth-child(2) > div{
    width: 100%;
    height: 5px;
    background: var(--intense-clr);
}

.hero-sample-ui-content-bottom > div{
    width: 45%;
    height: 100%;
    background: var(--main-light);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    border-radius: 5px;
    display: flex;
    padding: 0.5em;
}
.hero-sample-ui-content-bottom > div:nth-child(2){
    transform: translateY(20px);
    animation: scales2 3s ease-in-out infinite alternate;
    -webkit-animation: scales2 3s ease-in-out infinite alternate;
}
.hero-sample-ui-content-bottom > div > div:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 0.4em;
    width: 70%;
    height: 100%;
    padding: 0.5em;
}
.hero-sample-ui-content-bottom > div > div:nth-child(1) span{
    width: 100%;
    height: 5px;
    background: var(--intense-clr);
    border-radius: 15px;
    box-shadow: var(--material-shadow);
}
.hero-sample-ui-content-bottom > div > div:nth-child(2){
    width: 30%;
    height: 100%;
}
.hero-sample-ui-content-bottom > div > div:nth-child(2) img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.hero-sample-ui-content-bottom-bar{
    width: 100%;
    height: 10%;
    background: var(--main-light);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em;
}

/* Media queries */

@media only screen and (max-width: 980px){
    .landing header{
        padding: 12em 4em 0 4em;
    }
    .hero-sample, .hero-sample img{
        display: none;
    }
    .hero-content{
        width: 100%;
        padding: 0;
    }
}
@media only screen and (max-width: 480px){
    .landing header{
        padding: 8em 2em 0 2em;
    }
    .hero-sample, .hero-sample img{
        display: none;
    }
    .hero-content{
        width: 100%;
        padding: 0;
    }

    #hero-waitlist-form > div{
        flex-direction: column;
    }
    #hero-waitlist-form > div button{
        width: 100%;
        border-radius: 5px;
    }
}
/* Stats */
.profile-landing-stats{
    width: 100%;
    display: flex;
    gap: 1em;
    margin-bottom: 2em;
}

.profile-landing-stats-single{
    position: relative;
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 2em;
    border-radius: 5px;
    box-shadow: var(--material-shadow);
    background: white;
}
.profile-landing-stats-single-top{
    width: 100%;
    display: flex;
}
.profile-landing-stats-single-top-content{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.profile-landing-stats-single-top-content p{
    font-size: 1.5rem;
    text-align: left;
    color: var(--secondary-clr);
}
.profile-landing-stats-single-top-content h1{
    color: var(--font-clr);
    text-align: left;
    font-size: 0.9rem;
    font-weight: 500;
    margin: 0;
}
.profile-landing-stats-single small{
    color: var(--font-light);
    text-align: left;
    font-size: 0.7rem;
    height: 20%;
}
.profile-landing-stats-single small span{
    font-weight: 700;
    color: var(--secondary-clr);
}
.profile-landing-stats-single-right span{
    color: var(--intense-clr);
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .profile-landing-stats{
        display: flex;
        flex-flow: wrap;
    }
    .profile-landing-stats-single{
        width: 100%;
    }
}
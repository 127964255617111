/* Conversation chats */
.conversation-messages, .conversation-messages-open{
    position: fixed;
    z-index: 10;
    bottom: 6em;
    right: 3em;
    width: 30%;
    height: 70%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background: var(--main-clr);
    border-radius: var(--radius);
    box-shadow: var(--material-shadow);
    border: none;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: all 0.1s;
}
.conversation-messages-open{
    transform: scaleY(1);
}
.conversation-messages-empty{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.conversation-messages-empty h1{
    font-weight: 300;
    color: var(--font-clr);
}
.conversation-messages-empty p{
    color: var(--font-clr);
}


/* Conversation chat single */
.conversation-messages-single{
    width: 100%;
    height: 100%;
    background: transparent;
}


.conversation-messages-single-top{
    position: relative;
    width: 100%;
    height: 15%;
    background: var(--secondary-clr);
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 1em;
}
.conversation-messages-single-top > div:nth-child(1){
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid var(--main-clr);
    color: var(--main-clr);
    display: flex;
    align-items: center;
    justify-content: center;
}
.conversation-messages-single-top > div:nth-child(2){
    color: var(--main-clr);
    font-size: 14px;
}
.conversation-messages-single-top > div:nth-child(2) span{
    color: var(--main-clr);
    font-weight: 500;
    text-transform: capitalize;
    font-size: 14px;
}
.conversation-messages-single-top > div:nth-child(3){
    position: absolute;
    right: 1em;
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: var(--main-clr);
}
.conversation-messages-single-top > div:nth-child(3) button{
    background: transparent;
    border: none;
    padding: 0;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-clr);
    cursor: pointer;
}

.conversation-messages-single-text, .conversation-messages-empty{
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    background: var(--main-clr);
}
.conversation-messages-single-text-title{
    height: 10%;
    font-weight: 300;
    color: var(--font-clr);
    font-size: 1rem;
    margin: 0;
    background: transparent;
}
.conversation-messages-single-text p{
    color: var(--font-clr);
}
.conversation-messages-single span{
    text-transform: capitalize;
    color: var(--secondary-clr);
}
.conversation-messages-single-messages{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em 0;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    scroll-snap-type: y proximity;
}
.conversation-messages-single-messages::-webkit-scrollbar {
  width: 10px;
}
.conversation-messages-single-messages-scroll-btn{
    position: absolute;
    top: 1em;
}


/* Single messages */
.conversation-messages-single-messages-wrapper{
    display: flex;
    align-items: center;
    gap: 0.3em;
    margin-left: 0.5;
    margin-right: 0.5;
}
.conversation-messages-single-messages-wrapper-options{
    position: absolute;
    top: -45px;
    display: flex;
    align-items: center;
    gap: 0.5em;
    border-radius: 25px;
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    padding: 0.3em;
    z-index: 40;
}
.conversation-messages-single-messages-wrapper-options button{
    width: 1.5em;
    height: 1.5em;
    border: none;
    border-radius: 50%;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.conversation-messages-single-messages-wrapper-options button:nth-child(2):hover{
    background: var(--warning-clr);
}
.conversation-messages-single-messages-wrapper-options button:nth-child(3):hover{
    background: var(--fail-clr);
}
.conversation-messages-single-messages-wrapper-options button:nth-child(1):hover{
    background: var(--intense-clr);
    color: var(--main-clr);
}
.conversation-messages-single-messages-img{
    width: 25px;
    height: 25px;
    background: var(--main-clr);
    color: var(--font-clr);
    border: 1px solid var(--border-clr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.conversation-messages-single-messages-sender-wrapper, .conversation-messages-single-messages-reciever-wrapper{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.2em;
}
.conversation-messages-single-messages-sender-wrapper p {
    color: var(--main-clr);
    text-align: right;
    font-size: 12px;
    user-select: none;
}
.conversation-messages-single-messages-reciever-wrapper p{
    color: var(--font-clr);
    text-align: left;
    font-size: 12px;
    user-select: none;
}
.conversation-messages-single-messages-reciever-wrapper{
    align-items: flex-start;
    gap: 0.2em;
}
.conversation-messages-single-messages-sender-message, .conversation-messages-single-messages-reciever-message{
    max-width: 80%;
    background: var(--secondary-clr);
    padding: 1em;
    border-radius: var(--radius) var(--radius) 0 var(--radius);
    cursor: pointer;
}
.conversation-messages-single-messages-reciever-message{
    background: var(--main-light);
    border-radius: var(--radius) var(--radius) var(--radius) 0;
}
.conversation-messages-single-messages-time{
    font-size: 10px;
    color: var(--font-light);
}


/* Conversation form */
#conversation-messages-form{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    padding: 1em;
    border-top: 1px solid var(--border-clr);
}
#conversation-messages-form input{
    border: none;
    outline: none;
    width: 90%;
    height: 100%;
}
.profile-conversation-attach-btn, .profile-conversation-send-btn{
    background: transparent;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    color: var(--font-clr);
}
.profile-conversation-send-btn{
    width: 40px;
    height: 40px;
    color: var(--main-clr);
    background: var(--secondary-clr);
    border-radius: 50%;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-conversation-send-btn:hover{
    background: var(--intense-clr);
}


/* Pending */
.conversation-messages-single-pending{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.874);
    /* backdrop-filter: blur(5px); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    padding: 2em;
}
.conversation-messages-single-pending small{
    color: var(--font-clr);
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    .conversation-messages, .conversation-messages-open{
        width: 90%;
        right: 1em;
    }
}

@media only screen and (max-width: 480px){
    .conversation-messages, .conversation-messages-open{
        width: 90%;
        right: 1em;
    }
}
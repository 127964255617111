.subscription-plans{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3em;
    padding: 8em 2em 3em 2em;
}
.subscription-plans-bg{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 200vh;
    transform: translate(-50%, -50%) rotate(45deg);
    background: var(--secondary-light);
    border-radius: 35px;
    z-index: -1;
}
.subscription-plans > h1{
    color: var(--main-clr);
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;
}
.subscription-plans > h5{
    margin: 0;
    color: var(--main-light);
    display: flex;
    align-items: center;
}

.subscription-plans-top{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.subscription-plans-top > div{
    display: flex;
    background: rgba(255, 255, 255, 0.459);
    padding: 0.4em;
    border-radius: 25px;
}
.subscription-plans-top-btn, .subscription-plans-top-btn-active{
    border-radius: 25px;
    background: transparent;
    border: none;
    padding: 1em 4em;
    color: var(--font-light);
    cursor: pointer;
    transition: transform 0.3s;
}
.subscription-plans-top-btn:hover{
    transform: scale(1.05);
}
.subscription-plans-top-btn-active{
    box-shadow: var(--material-shadow);
    background: var(--main-clr);
}
.subscription-plans-top small{
    color: var(--third-clr);
    font-size: 0.6rem;
}


.subscription-plans-options{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
}
.subscription-plans-options-single{
    background: #e7e7e7;
    position: relative;
    width: 32%;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 25px;
    padding: 3em 0.5em 0.5em 0.5em;
}
.subscription-plans-options-single-label{
    position: absolute;
    top: 1em;
    font-size: 0.9rem;
    color: var(--font-light);
    text-transform: uppercase;
}
.subscription-plans-options-single-container, .subscription-plans-options-single-container-active{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius:25px;
    padding: 4em 2em;
    background: var(--main-clr);
    transition: transform 0.3s;
}


.subscription-plans-options-single-top{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 1.5em;
}
.subscription-plans-options-single-icon, .subscription-plans-options-single-icon-active{
    background: var(--secondary-clr);
    border-radius: 50%;
    width: 3em;
    height: 3em;
    color: var(--main-clr);
    padding: 0.8em;
}
.subscription-plans-options-single-icon-active{
    background: var(--main-clr);
    color: var(--secondary-clr);
    margin: 0;
}
.subscription-plans-options-single h5{
    color: var(--font-clr);
    text-align: center;
    margin: 0;
}
.subscription-plans-options-single-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5em;
    width: 70%;
}
.subscription-plans-options-single-list li{
    font-size: 0.8rem;
    text-align: left;
    color: var(--font-light);
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.subscription-plans-options-single-list-icon{
    color: var(--success-clr);
}


.subscription-plans-options-single-bottom{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 1.5em;
}
.subscription-plans-options-single-bottom-btns{
    display: flex;
    align-items: center;
    gap: 1em;
}
.subscription-plans-options-single-free-btn{
    border: none;
    background: transparent;
    cursor: pointer;
    text-decoration: underline;
}
.subscription-plans-options-single h6{
    position: relative;
    font-size: 2rem;
    color: var(--font-clr);
    font-weight: 300;
    margin: 0;
    margin-top: 2em;
}
.subscription-plans-options-single h6 span{
    font-size: 0.8rem;
}
.subscription-plans-options-single h6::before{
    content: "$";
    position: absolute;
    top: 2px;
    left: -10px;
    font-size: 0.9rem;
}
.subscription-plans-options-single-current{
    color: var(--third-clr-intense);
}
.subscription-plans-options-single-btn, .subscription-plans-options-single-btn-disabled{
    background: var(--secondary-clr);
    text-decoration: none;
    padding: 1em 4em;
    border: none;
    border-radius: 25px;
    color: var(--main-clr);
    cursor: pointer;
    transition: background 0.3s;
}
.subscription-plans-options-single-btn:hover{
    background: var(--third-clr);
}
.subscription-plans-options-single-btn-disabled{
    background: var(--main-light);
    color: var(--font-light);
    pointer-events: none;
    user-select: none;
}

.subscription-plans-options-single-container-active{
    background: var(--secondary-clr);
}
.subscription-plans-options-single-container-active h5, .subscription-plans-options-single-container-active h6{
    color: var(--main-clr);
}
.subscription-plans-options-single-container-active li{
    color: var(--main-light);
}
.subscription-plans-options-single-container-active .subscription-plans-options-single-btn{
    background: var(--main-clr);
    color: var(--secondary-clr);
}
.subscription-plans-options-single-container-active .subscription-plans-options-single-btn:hover{
    background: var(--third-clr);
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    .subscription-plans{
        padding: 8em 0em 3em 0em;
    }
    .subscription-plans > h1{
        color: var(--font-clr);
    }

    .subscription-plans-options{
        flex-direction: column;
    }
    .subscription-plans-options-single{
        width: 60%;
        height: max-content;
    }
}

@media only screen and (max-width: 480px){
    .subscription-plans{
        padding: 8em 0em 3em 0em;
    }
    .subscription-plans > h1{
        color: var(--font-clr);
    }

    .subscription-plans-options{
        flex-direction: column;
    }
    .subscription-plans-options-single{
        width: 100%;
        height: max-content;
    }
}
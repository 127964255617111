.profile-landing{
    width: 100%;
    display: flex;
    background: var(--main-light);
}
.profile-landing-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2em;
    padding-right: 3em;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1em;
}

/* Header */
.profile-landing-header{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.profile-landing-header h1 {
    margin: 0;
    text-align: left;
    color: var(--font-clr);
    font-weight: 300;
    font-size: 1.4rem;
}
.profile-landing-header h1 span{
    text-transform: capitalize;
    font-size: 1.4rem;
}
.profile-landing-header p{
    text-align: left;
    color: var(--font-light);
    font-size: 12px;
    line-height: 1.5em;
}

.profile-landing-subtitle{
    color: var(--font-light);
    text-align: left;
    font-size: 14px;
}



.profile-landing-changes, .profile-landing-actions{
    width: 100%;
}
.profile-landing-changes h1, .profile-landing-actions h1, .profile-landing-activity h1, .profile-landing-news h1, .profile-landing-links h1{
    margin: 0;
    text-align: left;
    color: var(--font-clr);
    font-weight: 700;
    font-size: 14px;
}
.profile-landing-changes p, .profile-landing-actions p{
    color: var(--font-light);
    text-align: left;
    font-size: 14px;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    
}

@media only screen and (max-width: 480px){
    .profile-landing-content{
        padding: 1em;
    }
}

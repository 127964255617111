.backlinks-table-items-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.backlinks-table-items-wrapper p{
    text-align: left;
    margin: 0.5em 0;
    font-size: 0.8rem;
    color: var(--font-light);
}
#new-connections-p{
    font-weight: 600;
    color: var(--font-clr);
}

/* Single table item */
.backlinks-table-items-single-wrapper{
    border-radius: 5px;
    box-shadow: var(--material-shadow);
}
.backlinks-table-items-empty{
    padding: 0.8em 1em;
    text-align: left;
    border-bottom: 1px solid var(--border-clr);
    font-size: 0.9rem;
    border-radius: 5px;
    color: var(--font-clr);
    box-shadow: var(--material-shadow-intense);
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.backlinks-table-items-titles, 
.backlinks-table-items-single, 
.backlinks-table-items-single-new,
.backlinks-table-items-single-active,
.backlinks-table-items-single-missing{
    width: 100%;
    display: grid;
    grid-template-columns: 5% 28% 28% 15% 15% 5% 4%;
    border-radius: 5px;
    background: var(--main-clr);
    transition: background 0.2s;
    will-change: background;
}
.backlinks-table-items-titles{
    border: none;
    background: rgb(240, 236, 255);
    border-radius: 5px;
}
.backlinks-table-items-single-active{
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
}
.backlinks-table-items-single-missing{
    grid-template-columns: 95% 5%;
    background: rgb(255, 148, 148);
}
.backlinks-table-items-single:hover, 
.backlinks-table-items-single-new:hover, 
.backlinks-table-items-single-missing:hover{
    background: var(--main-light-plus);
}

.backlinks-table-items-titles > div, 
.backlinks-table-items-single > div, 
.backlinks-table-items-single-new > div,
.backlinks-table-items-single-missing > div{
    padding: 0.8em 1em;
    font-size: 0.8rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.3em;
    color: var(--font-light);
    white-space: nowrap;
}
.backlinks-table-items-titles > div{
    font-size: 0.8rem;
    color: var(--font-clr);
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5em;
}
.backlinks-table-items-titles > div:nth-child(2),
.backlinks-table-items-titles > div:nth-child(3),
.backlinks-table-items-titles > div:nth-child(4){
    cursor: pointer;
}
.backlinks-table-items-single-missing > div{
    color: var(--fail-clr);
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
}

.backlinks-table-items-titles > div p, 
.backlinks-table-items-single > div p, 
.backlinks-table-items-single-new > div p,
.backlinks-table-items-single-missing > div p{
    margin: 0;
}

.backlinks-table-items-icon-disabled{
    color: rgb(217, 217, 217);
    font-size: 1rem;
}

/* Status */
.backlinks-table-items-single-status-approved, 
.backlinks-table-items-single-status-pending,
.backlinks-table-items-single-status-new{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.backlinks-table-items-single-status-approved span{
    color: #00ff33;
    margin: 0;
    transform: scale(1.2);
}
.backlinks-table-items-single-status-pending span{
    color: rgb(255, 170, 34);
    transform: scale(1.2);
    margin: 0;
}
.backlinks-table-items-single-status-new span{
    color: rgb(0, 64, 255);
    transform: scale(1.4);
    margin: 0;
}

.backlinks-table-items-single-niche p{
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: context-menu;
}


/* Approval */
.backlinks-table-items-single-approval{
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--main-light-plus);
    border-radius: 0 0 5px 5px;
    /* margin: 0.5em; */
}
.backlinks-table-items-single-approval p{
    color: var(--font-clr);
}
.backlinks-table-items-single-approval p span{
    color: var(--secondary-clr);
    font-weight: 500;
}
.backlinks-table-items-single-approval-btns{
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.backlinks-table-items-single-approval-btns button{
    background: var(--success-clr);
    color: var(--main-clr);
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.3s;
}
#backlinks-table-items-single-reject-btn{
    background: var(--fail-clr);
}
.backlinks-table-items-single-approval-btns button:hover{
    transform: scale(1.1);
}


/* Actions */
.backlinks-table-items-single-actions{
    position: relative;
}
.backlinks-table-items-single-actions-options{
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    border-radius: 5px;
    overflow: hidden;
    position: absolute;
    z-index: 20;
    top: 35px;
    right: 0;
    display: flex;
    flex-direction: column;
}
.backlinks-table-items-single-actions-options button{
    background: transparent;
    border: none;
    padding: 0.5em 1em;
    cursor: pointer;
}
.backlinks-table-items-single-actions-options button:hover{
    background: var(--main-light-plus);
}
.backlinks-table-items-single-actions-options button:disabled{
    color: grey;
    pointer-events: none;
    user-select: none;
}
.backlinks-table-items-single-actions-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: red;
}

/* Links */
.backlinks-table-items-single-link{
    text-decoration: none;
    color: var(--intense-clr);
}
.backlinks-table-items-single-link:hover{
    color: black;
}

/* Buttons */
.backlinks-table-items-single-actions-btn, 
.backlinks-table-items-single-chat-btn,
.backlinks-table-items-single-missing-btn{
    position: relative;
    background: transparent;
    border: none;
    color: var(--secondary-clr);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    font-size: 1rem;
}
.backlinks-table-items-single-missing-btn{
    color: var(--fail-clr);
}
.backlinks-table-items-single-chat-btn:hover::after,
.backlinks-table-items-single-missing-btn:hover::after{
    opacity: 1;
}
.backlinks-table-items-single-chat-btn::after, 
.backlinks-table-items-single-missing-btn::after{
    content: "Open conversation";
    position: absolute;
    right: 0;
    top: -30px;
    font-size: 0.8rem;
    background: rgba(0, 0, 0, 0.9);
    color: var(--main-clr);
    padding: 0.4em 0.8em;
    border-radius: 5px;
    white-space: nowrap;
    text-align: left;
    user-select: none;
    pointer-events: none;
    opacity: 0;
}
.backlinks-table-items-single-missing-btn::after{
    content: "Remove connection";
    right: 50%;
    transform: translateX(50%);
}
.backlinks-table-items-single-chat-btn:disabled{
    color: grey;
    user-select: none;
    pointer-events: none;
}
.backlinks-table-items-single-chat-btn-span{
    position: absolute;
    color: var(--main-clr);
    background: red;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: -4px;
    right: -4px;
    font-size: 0.7rem;
}


@media only screen and (max-width: 980px){
   
}
@media only screen and (max-width: 480px){
    .backlinks-table-items-single > div:nth-child(4), .backlinks-table-items-titles > div:nth-child(4){
        background: yellow;
        display: none;
    }
    .backlinks-table-items-titles > div{
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.features{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    padding: 4em 8em;
}
.features-top{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-bottom: 2em;
}
.features-top h1{
    position: relative;
    width: 60%;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1em;
    color: var(--font-title-clr);
    margin: 0;
    text-align: left;
    font-family: 'Work Sans', sans-serif;
}
.features-top p{
    width: 60%;
    color: var(--font-landing-clr);
    text-align: center;
    margin: 0;
    text-align: left;
}



.features-single-wrapper{
    width: 100%;
    height: max-content;
    display: flex;
    gap: 2em;
}


.features-single-wrapper-img{
    width: 60%;
    overflow: hidden;
    background: rgb(84,0,255);
    background: linear-gradient(177deg, rgba(84,0,255,1) 0%, rgba(255,255,255,1) 100%);    
    border-radius: 5px;
    box-shadow: var(--material-shadow);
    display: flex;
    flex-direction: column;
    gap: 10em;
}
.features-single-wrapper-img img{
    height: 30%;
    object-fit: cover;
    transform: scale(1.3) rotate(-20deg);
}
.features-single-wrapper-img img:nth-child(2){
    transform: scale(1.8) rotate(-25deg);
}


.features-single-wrapper-content{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.features-single{
    border: 1px solid var(--border-clr);
    background: var(--main-clr);
    border-radius: 5px;
    box-shadow: var(--material-shadow);
    display: flex;
    transition: background 0.2s;
}
.features-single:hover {
    background: var(--secondary-clr);
}
.features-single:hover .features-single-content h5, .features-single:hover .features-single-content p{
    color: var(--main-clr);
}
.features-single-top{
    position: relative;
    width: 5px;
    height: 100%;
    background: var(--secondary-clr);
    border-radius: 5px;
}
.features-single-top-icon{
    position: absolute;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: var(--secondary-clr);
}

.features-single-content{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 2em 1em;
}
.features-single-content h5{
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    color: var(--font-title-clr);
    margin: 0;
    transition: color 0.2s;
}
.features-single-content p{
    font-size: 0.8rem;
    color: var(--font-landing-clr);
    text-align: left;
    line-height: 1.5em;
    margin: 0;
    transition: color 0.2s;
}


.features-bottom{
    width: 100%;
    background: var(--secondary-clr);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2em;
}
.features-bottom p{
    color: var(--main-clr);
}
.features-bottom-link{
    padding: 0.5em 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
    background: var(--main-clr);
    color: var(--secondary-clr);
    text-decoration: none;
    cursor: pointer;
}
.features-bottom-link:hover{
    background: var(--third-clr);
}

@media only screen and (max-width: 980px){
    .features{
        padding: 4em;
    }
    .features-single-top-icon{
        display: none;
    }
}
@media only screen and (max-width: 480px){
    .features{
        padding: 2em;
    }
    .features-top{
        width: 100%;
    }
    .features-top h1{
        font-size: 2rem;
        width: 100%;
    }
    .features-top p{
        width: 100%;
    }

    .features-single-wrapper-img{
        display: none;
    }
    .features-single-wrapper-content{
        width: 100%;
    }
    .features-single{
        width: 100%;
    }
    .features-single-top-icon{
        display: none;
    }

    .features-bottom{
        flex-direction: column;
        gap: 1em;
    }
}
.forgot-password-wrapper, .forgot-password-reset{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2em;
    padding-top: 2em;
}
.forgot-password-reset{
    justify-content: center;
    align-items: center;
}
.forgot-password-reset small span{
    font-weight: 500;
    text-transform: capitalize;
}

.forgot-password-top{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}
.forgot-password-top p, .forgot-password-title{
    color: var(--font-title-clr);
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
}
.forgot-password-top small, .forgot-password-top small{
    font-size: 0.9rem;
    color: var(--font-light);
    margin: 0;
}
.forgot-password-icon{
    width: 12em;
    height: 12em;
    border: 1px solid var(--font-clr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.forgot-password-icon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


#forgot-password-form, #forgot-password-reset-form{
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}


#forgot-password-form div, #forgot-password-reset-form div{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: var(--font-clr);
}
#forgot-password-form div:focus-within, #forgot-password-reset-form div:focus-within{
    border-bottom: 2px solid var(--secondary-clr);
}
#forgot-password-form div input, #forgot-password-reset-form div input{
    outline: none;
    width: 90%;
    height: 3em;
    background: transparent;
    border: none;
    color: var(--font-clr);
}


#forgot-password-form button, .change-password-btn{
    width: 100%;
    background: var(--secondary-clr);
    border: none;
    border-radius: var(--radius);
    color: white;
    padding: 1em 2em;
    cursor: pointer;
    transition: background 0.3s;
    will-change: background;
}
#forgot-password-form button:hover, .change-password-btn:hover{
    background: var(--intense-clr);
}
.forgot-password-form-error{
    text-align: left;
    color: var(--fail-clr);
    font-size: 0.8rem;
    margin: 0;
}


.forgot-password-new-link{
    text-decoration: none;
    font-size: 0.9rem;
    color: var(--secondary-clr);
}

.forgot-password-complete{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.918);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    padding: 2em;
}
.forgot-password-complete p{
    font-size: 1.5rem;
    color: var(--font-clr);
    margin: 0;
}
.forgot-password-complete small{
    width: 30%;
    color: var(--font-light);
    margin: 0;
}
.forgot-password-complete-btn{
    background: transparent;
    border: none;
    color: var(--secondary-clr);
    font-size: 1.2rem;
    cursor: pointer;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 480px){
    .forgot-password-wrapper{
        padding: 2em;
    }
    .forgot-password-top{
        width: 100%;
    }
    #forgot-password-form, #forgot-password-reset-form{
        width: 100%;
        padding: 1em;
    }
    .forgot-password-complete small{
        width: 100%;
    }
}

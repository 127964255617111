.interface{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    z-index: -1;
    background: var(--main-light);
}



.inteface-bottom{
    background: var(--main-clr);
    display: flex;
    width: 100%;
    height: 100%;
    padding: 8em;
}
.interface-content{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.interface-content h1{
    text-align: left;
    font-size: 2.5rem;
    line-height: 1.2em;
    margin: 0;
    color: var(--font-title-clr);
    font-family: 'Work Sans', sans-serif;
}
.interface-content p{
    text-align: left;
    line-height: 1.5em;
    margin: 0;
    color: var(--font-landing-clr);
}
.interface-content > div{
    margin: 2em 0;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.interface-content > div li{
    text-align: left;
    color: var(--secondary-clr);
    list-style-type: none;
    font-weight: 300;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.interface-link{
    background: var(--secondary-clr);
    border: none;
    border-radius: 5px;
    color: var(--main-clr);
    padding: 0.5em 1em;
    width: max-content;
    font-size: 1.1rem;
    text-decoration: none;
    cursor: pointer;
}
.interface-link:hover{
    background: var(--third-clr);
}

.interface-sample{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
}
.interface-sample-bar{
    width: 60%;
    padding: 0.5em;
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.interface-sample-bar span{
    margin: 0;
    font-size: 0.7rem;
    color: var(--font-clr);
}
.interface-sample-bar div{
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-size: 0.7rem;
    color: var(--font-clr);
}


.interface-sample-shape{
    width: 60%;
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    border-radius: 5px 5px 25% 25%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 1em 5em 1em;
}
#interface-shape-1{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#interface-shape-1 > div{
    display: flex;
    flex-direction: column;
}
#interface-shape-1 > div h5{
    text-align: left;
    font-size: 0.8rem;
    margin: 0;
    color: var(--font-clr);
}
#interface-shape-1 > div p{
    text-align: left;
    margin: 0;
    font-size: 0.8rem;
    color: var(--font-clr);
}
#interface-shape-1 span{
    position: relative;
}
#interface-shape-1 span::before{
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    width: 6px;
    height: 6px;
    background: var(--fail-clr);
    border-radius: 50%;
}
#interface-shape-2, #interface-shape-3{
    width: 100%;
    background: var(--secondary-clr);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    box-shadow: var(--material-shadow);
    transition: transform 0.2s;
}
#interface-shape-2 h5, #interface-shape-3 h5{
    margin: 0;
    text-align: left;
    color: var(--main-light);
}
#interface-shape-2 span, #interface-shape-3 span{
    text-align: left;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--main-clr);
}
#interface-shape-2 small, #interface-shape-3 small{
    text-align: left;
    font-size: 0.6rem;
    background: rgba(0, 0, 0, 0.438);
    width: max-content;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: var(--main-clr);
}
#interface-shape-3{
    background: var(--third-clr);
}
#interface-shape-2:hover, #interface-shape-3:hover{
    transform: scale(1.1);
}


@media only screen and (max-width: 980px){
    .inteface-bottom{
        padding: 4em;
    }
    .interface-sample-shape, .interface-sample-bar{
        width: 90%;
    }
}
@media only screen and (max-width: 480px){
    .inteface-bottom{
        flex-direction: column;
        padding: 4em 0 0 0;
    }
    .interface-content{
        width: 100%;
        padding: 2em;
    }
    .interface-content h1{
        font-size: 2rem;
    }
    .interface-sample{
        display: none;
    }
}
.nav{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    gap: 2em;
    align-items: center;
    padding: 1em 3em;
    transition: background 0.2s;
    z-index: 10;
}

.nav-logo{
    display: flex;
    align-items: center;
    gap: 0.5em;
    text-decoration: none;
}
.nav img{
    width: 30px;
    height: 30px;
}
.nav-logo h1{
    color: var(--secondary-clr);
    font-size: 1.1rem;
}

/* Nav menu links */
.nav-menu{
    display: flex;
    align-items: center;
    gap: 2em;
}
.nav-menu-links{
    position: relative;
    text-decoration: none;
    color: var(--font-landing-clr);
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.5em;
    transition: all 0.2s;
    transform-origin: top;
}
.nav-menu-links:hover{
    color: var(--secondary-clr);
    border-radius: 5px;
}
.nav-menu-links:hover::after{
    transform: scaleX(1);
}
.nav-menu-links::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 1px;
    background: var(--secondary-clr);
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.2s;
}


.nav-menu-links-expand{
    position: relative;
}
.nav-menu-links-expand:hover .nav-menu-links-expand-content{
    display: flex;
}
.nav-menu-links-expand-content{
    box-shadow: var(--material-shadow);
    background: var(--main-clr);
    border-radius: 5px;
    border: 1px solid var(--border-clr);
    position: absolute;
    top: 30px;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    display: none;
}
.nav-menu-links-expand-content div{
    width: max-content;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 1em 2em;
}
.nav-menu-links-expand-content div:hover{
    background: var(--main-light);
}
.nav-menu-links-expand-content div p{
    text-align: left;
    color: var(--font-clr);
    font-weight: 500;
    font-size: 0.9rem;
}
.nav-menu-links-expand-content div small{
    text-align: left;
    color: var(--font-light);
    font-size: 0.8rem;
}

.nav-right{
    position: absolute;
    right: 1em;
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.nav-account{
    position: absolute;
    right: 2em;
    display: flex;
    align-items: center;
    gap: 1em;
}
.login-link{
    text-decoration: none;
    background: transparent;
    border: none;
    border-radius: 5px;
    padding: 0.5em 1em;
    color: var(--intense-clr);
    background: var(--main-light);
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s;
    will-change: color;
}
.login-link:hover{
    background: var(--third-clr);
    border-color: var(--third-clr);
}
.signup-link{
    width: max-content;
    text-decoration: none;
    background: var(--intense-clr);
    color: var(--main-clr);
    font-size: 0.9rem;
    border: none;
    border-radius: 5px;
    padding: 0.5em 1em;
    cursor: pointer;
    transition: all 0.2s;
    will-change: background;
}
.signup-link:hover{
    background: var(--third-clr);
    color: var(--secondary-clr);
}
.nav-mobile-menu-btn{
    display: none;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: var(--font-landing-clr);
}


/* Nav user */
.nav-user{
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.nav-user-upgrade-btn:hover{
    background: var(--secondary-clr);
    color: var(--main-clr);
}
.nav-user p{
    font-size: 0.9rem;
    color: var(--font-clr);
}
.nav-user span{
    color: var(--font-clr);
    font-weight: 500;
    text-transform: capitalize;
}
.nav-user-notifications{
    position: relative;
    background: transparent;
    border: none;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav-user-notifications-btn{
    position: relative;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: var(--font-clr);
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-user-notifications-count{
    position: absolute;
    top: -5px;
    right: -2px;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #CC0000;
    font-size: 0.7rem;
    color: var(--main-clr);
    border: 2px solid var(--main-clr);
    display: flex;
    align-items: center;
    justify-content: center;
}


.nav-user-profile-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
}
.nav-user-profile{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1AB7EA;
}
.nav-user-profile p{
    color: var(--main-clr);
}
.nav-user-options{
    position: absolute;
    z-index: 100;
    right: 0;
    top: 38px;
    width: 250px;
    border-radius: 5px;
    background: var(--main-clr);
    box-shadow: var(--material-shadow);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}


.nav-options-top{
    height: 70px;
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 1em;
    border-top: 1px solid  var(--border-clr);
}
.nav-options-top-img{
    width: 2em;
    height: 2em;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-clr);
}
.nav-user-options p{
    text-align: left;
    margin: 0;
    color: var(--font-clr);
    font-weight: 600;
}
.nav-user-options small{
    text-align: left;
    margin: 0;
    font-size: 0.8rem;
    color: var(--font-light);
}


.nav-user-options-plan{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5em;
}
.nav-user-options-plan p{
    background: var(--third-clr);
    color: var(--secondary-clr);
    border: 1px solid var(--border-clr);
    border-radius: 5px;
    padding: 0.5em 1em;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.nav-user-options-plan p span{
    text-transform: capitalize;
    color: var(--secondary-clr);
}
.nav-user-options-plan p small{
    color: var(--secondary-light);
    font-size: 0.8rem;
}


.nav-options-btns{
    height: max-content;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.nav-options-btns::-webkit-scrollbar {
    width: 10px;
}
.nav-options-btns::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.nav-options-btns::-webkit-scrollbar-thumb {
    background: #888;
}
.nav-options-btns::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.nav-options-btn{
    text-decoration: none;
    background: transparent;
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--border-clr);
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 1em;
    font-size: 0.9rem;
    color: var(--font-clr);
    cursor: pointer;
    transition: background 0.3s;
    will-change: background;
}
.nav-options-btn:hover{
    background: var(--main-light);
}


.nav-user-menu-btn{
    background: transparent;
    border: none;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    color: var(--font-clr);
    cursor: pointer;
}


/* MEDIA QUERIES */
@media only screen and (max-width: 980px){
    .nav{
        padding: 2em 1em;
    }
    .nav-menu-links{
        display: none;
    }
    .nav-mobile-menu-btn{
        display: block;
    }

    .nav-user-notifications-btn{
        font-size: 1.3rem;
    }

    .nav-user{
        right: 3.5em;
    }
    .nav-user > p{
        display: none;
    }

    .nav-user-menu-btn{
        display: flex;
    }
}

@media only screen and (max-width: 480px){
    .nav{
        padding: 2em 1em;
    }
    .nav-logo h1{
        display: none;
    }
    .nav-menu-links{
        display: none;
    }
    .nav-mobile-menu-btn{
        display: block;
    }

    .nav-user-notifications-btn{
        font-size: 1.3rem;
    }

    .nav-user{
        right: 3.5em;
    }
    .nav-user > p{
        display: none;
    }

    .nav-user-menu-btn{
        display: flex;
    }
}